import React from "react";
import { useSelector } from "react-redux";
import { RootState } from "../../../store";
import { ProjectTile } from ".";

export const ProjectsListInTiles = () => {
  const { list: projectsList } = useSelector((state: RootState) => state.project);

  return (
    <>
      <div className="grid-View m-t">
        {projectsList.map((project, index) => (
          <ProjectTile key={`project-tile-${index}`} project={project} />
        ))}
      </div>
    </>
  );
};
