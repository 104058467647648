import React from "react";
import { IconsProps } from "./Icons";

export const MapButtonIcon = ({ className, fill, width, height, viewBox }: IconsProps) => {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      className={className}
      viewBox={viewBox ? viewBox : "0 0 12 16"}
      width={width ? width : "12"}
      height={height ? height : "16"}
      fill={fill ? fill : "currentColor"}
    >
      <path
        xmlns="http://www.w3.org/2000/svg"
        d="M5.6 3.6C6.13043 3.6 6.63914 3.81071 7.01421 4.18579C7.38929 4.56086 7.6 5.06957 7.6 5.6C7.6 5.86264 7.54827 6.12272 7.44776 6.36537C7.34725 6.60802 7.19993 6.8285 7.01421 7.01421C6.8285 7.19993 6.60802 7.34725 6.36537 7.44776C6.12272 7.54827 5.86264 7.6 5.6 7.6C5.06957 7.6 4.56086 7.38929 4.18579 7.01421C3.81071 6.63914 3.6 6.13043 3.6 5.6C3.6 5.06957 3.81071 4.56086 4.18579 4.18579C4.56086 3.81071 5.06957 3.6 5.6 3.6ZM5.6 0C7.08521 0 8.50959 0.589998 9.5598 1.6402C10.61 2.69041 11.2 4.11479 11.2 5.6C11.2 9.8 5.6 16 5.6 16C5.6 16 0 9.8 0 5.6C0 4.11479 0.589998 2.69041 1.6402 1.6402C2.69041 0.589998 4.11479 0 5.6 0ZM5.6 1.6C4.53913 1.6 3.52172 2.02143 2.77157 2.77157C2.02143 3.52172 1.6 4.53913 1.6 5.6C1.6 6.4 1.6 8 5.6 13.368C9.6 8 9.6 6.4 9.6 5.6C9.6 4.53913 9.17857 3.52172 8.42843 2.77157C7.67828 2.02143 6.66087 1.6 5.6 1.6Z"
        fill="currentColor"
      />
    </svg>
  );
};
