import Table, { ColumnsType } from "antd/lib/table";
import React, { useCallback, useEffect, useState } from "react";
import { useDispatch } from "react-redux";
import { DeleteIcon } from "../../../assets/icons";
import { removeExclusion, removeInclusion, setAnalyzed } from "../../../store";
import { CRITERIAS } from "../../../utils";
import { getCriteriaEntry } from "./criteriaUtil";

interface DataType {
  key: number;
  criteria: JSX.Element;
  action: string;
}

const getColumns = (title: string, onDeleteClick: (id: number) => void): ColumnsType<DataType> => [
  {
    title: title,
    dataIndex: "criteria",
  },
  {
    title: "",
    dataIndex: "action",
    render: (value, record) => (
      <DeleteIcon className="cursor-pointer" onClick={() => onDeleteClick(record.key)} />
    ),
  },
];

interface Props {
  title: string;
  data: IAnalyzeSelectedInclusionExclusionCriteria[];
  updateSelectedCriteriaIds: (data: number[]) => void;
}

export const AnalyzeCriteria = (props: Props) => {
  const dispatch = useDispatch();
  const { title, data, updateSelectedCriteriaIds } = props;
  const [selectedIds, setSelectedIds] = useState([] as number[]);
  const [deletedHighlightEnteries, setDeletedHighlightEnteries] = useState([] as number[]);

  const onDeleteClick = (entryId: number) => {
    // if (!deletedHighlightEnteries.includes(entryId))
    //   setDeletedHighlightEnteries([...deletedHighlightEnteries, entryId]);
    dispatch(setAnalyzed(false));
    if (title === CRITERIAS.INCLUSION_CRITERIA) {
      dispatch(removeInclusion(entryId));
    } else if (title === CRITERIAS.EXCLUSION_CRITERIA) {
      dispatch(removeExclusion(entryId));
    }
    setSelectedIds((prevSelectedIds) => [...prevSelectedIds.filter((id) => id !== entryId)]);
  };

  const updateSelectedIds = useCallback(
    (selectedRowIds: number[]) => {
      setSelectedIds(selectedRowIds);
      updateSelectedCriteriaIds(selectedRowIds);
    },
    [updateSelectedCriteriaIds]
  );

  const rowSelection = {
    selectedRowKeys: selectedIds,
    onChange: (selectedRowKeys: React.Key[], selectedRows: DataType[]) => {
      const selectedRowIds = selectedRows.map((item) => item.key);
      updateSelectedIds(selectedRowIds);
      dispatch(setAnalyzed(false));
    },
  };
  const dataSource: DataType[] = data.map((item) => ({
    key: item.id,
    action: "",
    criteria: (
      <p
        style={{ lineHeight: 2.2 }}
        dangerouslySetInnerHTML={{
          __html: getCriteriaEntry({
            criteria: item.criterion,
            removeHighlight: deletedHighlightEnteries.includes(item.id),
          }).content,
        }}
      />
    ),
  }));

  useEffect(() => {
    updateSelectedIds(data.filter((item) => item.isSelected).map((item) => item.id));
  }, [data, updateSelectedIds]);

  return (
    <Table
      className="basic-detail-content tb-scroll two-row checkbox-table m-b icon-row"
      rowSelection={rowSelection}
      columns={getColumns(title, onDeleteClick)}
      dataSource={dataSource}
      pagination={false}
    />
  );
};
