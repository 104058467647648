import React, { useEffect } from "react";
import { useSelector } from "react-redux";
import { Outlet, useNavigate } from "react-router-dom";
import { autonomizeFullLogo, autonomizeFullLogoBlack } from "../assets/images";
import { RootState } from "../store";
import { PAGE_URL } from "../utils";

export const GuestLayout = () => {
  const navigate = useNavigate();
  const { accessToken } = useSelector((state: RootState) => state.user).user;

  useEffect(() => {
    if (accessToken && accessToken.length > 0) navigate(PAGE_URL.PROJECTS);
  }, [accessToken, navigate]);

  return (
    <div className="user-page flex aic jcc">
      <div className="user-page-container flex flex-column gp-40">
        <div className="brand-logo m-b flex">
          <img
              className="brand-logo-img"
              src={autonomizeFullLogo}
              alt="Autonomize Logo"
              // width={202}
              // height={40}
            />
            <h1 className="brand-title">P<span className="brand-title-highlight">i</span>xel</h1>
        </div>
        <Outlet />
      </div>
    </div>
  );
};
