import React, { useEffect } from "react";
import { useSelector } from "react-redux";
import { CreateFirstProjectContainer, PageWrapper, ProjectsListContainer } from "../containers";
import { useProject } from "../hooks";
import { RootState } from "../store";

const Projects = () => {
  const { getProjects, isProjectsLoading } = useProject();
  const { length: projectsLength } = useSelector((state: RootState) => state.project.list);

  useEffect(() => {
    getProjects();
  }, [getProjects]);

  return (
    <PageWrapper
      loading={isProjectsLoading}
      pageContainerChildren={
        projectsLength > 0 ? <ProjectsListContainer /> : <CreateFirstProjectContainer />
      }
    />
  );
};

export default Projects;
