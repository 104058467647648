import React, { useState } from "react";
import { Button } from "antd";
import { Dropdown } from "..";
import { downloadFile, exportToCsv } from "./cohortExportUtil";

const EXPORT_OPTIONS: IDropdownOption[] = [
  { id: 1, value: "JSON" },
  { id: 2, value: "CSV" },
];

interface Props {
  data: ICohortPatientTableDataType[];
}

export const CohortPatientsExport = ({ data }: Props) => {
  const [exportAs, setExportAs] = useState(EXPORT_OPTIONS[1]);

  const onCohortExportClick = (exportType: string) => {
    switch (exportType) {
      case EXPORT_OPTIONS[0].value:
        downloadFile({
          data: JSON.stringify(data),
          fileName: "result.json",
          fileType: "text/json",
        });
        break;
      case EXPORT_OPTIONS[1].value:
        exportToCsv(data, ",");
        break;
    }
  };

  return (
    <div className="flex no-wrap gp aic data-export jce">
      <p>Export As: </p>
      <div style={{ width: 210 }}>
        <Dropdown
          value={exportAs.value}
          loading={false}
          options={EXPORT_OPTIONS}
          onSelect={(value, option) => setExportAs(option)}
        />
      </div>
      <Button className="outline" onClick={() => onCohortExportClick(exportAs.value)}>
        Export
      </Button>
    </div>
  );
};
