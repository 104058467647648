import { createSlice, PayloadAction } from "@reduxjs/toolkit";

export const protocolinsightsSelectedProjectInitialState: IDropdownOption = {
  id: -1,
  value: "Select Data Source",
};

export const protocolinsightsInitialState: IProtocolInsightsSlice = {
  nctId: "",
  protocolinsightsCriteria: {
    exclusion: "",
    inclusion: "",
  },
  requestId: "",
  patients: [] as InclusionExclusionCriteriaPatientRecord[],
  patientsError: "",
  analyzeProtocolInsightsCriteria: {
    exclusion: [] as IAnalyzeSelectedInclusionExclusionCriteria[],
    inclusion: [] as IAnalyzeSelectedInclusionExclusionCriteria[],
  },
  selectedProject: protocolinsightsSelectedProjectInitialState,
  protocolinsightsPatientId: "",
  postgreSQL: "",
  analyzed: false,
};

export const protocolinsightsSlice = createSlice({
  name: "ui",
  initialState: protocolinsightsInitialState,
  reducers: {
    updateNctId: (state: IProtocolInsightsSlice, { payload }: PayloadAction<string>) => {
      state.nctId = payload;
    },
    updateInclusionCriteria: (state: IProtocolInsightsSlice, { payload }: PayloadAction<string>) => {
      state.protocolinsightsCriteria.inclusion = payload;
    },
    updateExclusionCriteria: (state: IProtocolInsightsSlice, { payload }: PayloadAction<string>) => {
      state.protocolinsightsCriteria.exclusion = payload;
    },
    updateRequestId: (state: IProtocolInsightsSlice, { payload }: PayloadAction<string>) => {
      state.requestId = payload;
    },
    updatePatients: (
      state: IProtocolInsightsSlice,
      { payload }: PayloadAction<InclusionExclusionCriteriaPatientRecord[]>
    ) => {
      state.patients = payload;
    },
    updatePatientsError: (state: IProtocolInsightsSlice, { payload }: PayloadAction<string>) => {
      state.patientsError = payload;
    },
    updatePostgreSQL: (state: IProtocolInsightsSlice, { payload }: PayloadAction<string>) => {
      state.postgreSQL = payload;
    },
    resetPatients: (state: IProtocolInsightsSlice) => {
      state.patients = protocolinsightsInitialState.patients;
    },
    resetPostgreSQL: (state: IProtocolInsightsSlice) => {
      state.postgreSQL = protocolinsightsInitialState.postgreSQL;
    },
    updateAnalyzeInclusionCriteria: (
      state: IProtocolInsightsSlice,
      { payload }: PayloadAction<IAnalyzeSelectedInclusionExclusionCriteria[]>
    ) => {
      state.analyzeProtocolInsightsCriteria.inclusion = payload;
    },
    updateSelectedAnalyzeInclusionCriteria: (
      state: IProtocolInsightsSlice,
      { payload }: PayloadAction<number[]>
    ) => {
      state.analyzeProtocolInsightsCriteria.inclusion = state.analyzeProtocolInsightsCriteria.inclusion.map((item) => ({
        ...item,
        isSelected: payload.includes(item.id),
      }));
    },
    updateAnalyzeExclusionCriteria: (
      state: IProtocolInsightsSlice,
      { payload }: PayloadAction<IAnalyzeSelectedInclusionExclusionCriteria[]>
    ) => {
      state.analyzeProtocolInsightsCriteria.exclusion = payload;
    },
    updateSelectedAnalyzeExclusionCriteria: (
      state: IProtocolInsightsSlice,
      { payload }: PayloadAction<number[]>
    ) => {
      state.analyzeProtocolInsightsCriteria.exclusion = state.analyzeProtocolInsightsCriteria.exclusion.map((item) => ({
        ...item,
        isSelected: payload.includes(item.id),
      }));
    },
    updateProtocolInsightsSelectedProject: (
      state: IProtocolInsightsSlice,
      { payload }: PayloadAction<IDropdownOption>
    ) => {
      state.selectedProject = payload;
    },
    resetProtocolInsights: (state: IProtocolInsightsSlice) => {
      state.nctId = protocolinsightsInitialState.nctId;
      state.analyzeProtocolInsightsCriteria = protocolinsightsInitialState.analyzeProtocolInsightsCriteria;
      state.protocolinsightsCriteria = protocolinsightsInitialState.protocolinsightsCriteria;
      state.selectedProject = protocolinsightsInitialState.selectedProject;
      state.patients = [];
      state.requestId = "";
      state.postgreSQL = "";
    },
    updateProtocolInsightsPatientId: (state: IProtocolInsightsSlice, { payload }: PayloadAction<string>) => {
      state.protocolinsightsPatientId = payload;
    },
    setAnalyzed: (state: IProtocolInsightsSlice, { payload }: PayloadAction<boolean>) => {
      state.analyzed = payload;
    },
    removeInclusion: (state: IProtocolInsightsSlice, { payload }: PayloadAction<number>) => {
      state.analyzeProtocolInsightsCriteria.inclusion = [
        ...state.analyzeProtocolInsightsCriteria.inclusion.filter((inclusion) => inclusion.id !== payload),
      ];
    },
    removeExclusion: (state: IProtocolInsightsSlice, { payload }: PayloadAction<number>) => {
      state.analyzeProtocolInsightsCriteria.exclusion = [
        ...state.analyzeProtocolInsightsCriteria.exclusion.filter((exclusion) => exclusion.id !== payload),
      ];
    },
  },
});

export const {
  updateNctId,
  updateAnalyzeExclusionCriteria,
  updateAnalyzeInclusionCriteria,
  updatePatients,
  updateRequestId,
  updatePatientsError,
  updateSelectedAnalyzeExclusionCriteria,
  updateSelectedAnalyzeInclusionCriteria,
  updateExclusionCriteria,
  updateInclusionCriteria,
  updateProtocolInsightsSelectedProject,
  resetProtocolInsights,
  updateProtocolInsightsPatientId,
  updatePostgreSQL,
  resetPatients,
  resetPostgreSQL,
  setAnalyzed,
  removeExclusion,
  removeInclusion,
} = protocolinsightsSlice.actions;

export default protocolinsightsSlice.reducer;
