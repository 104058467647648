import React from "react";
import { useSelector } from "react-redux";
import { RootState } from "../../store";
import { MODAL_TYPE } from "../../utils";
import DeleteConfirmation from "./delete-confirmation";
import { EditProfile } from "./edit-profile";
import ForgotPassword from "./forgot-password";
import CriteriaCategories from "./criteria-categories";
import AddEditProject from "./add-edit-project";
import UploadPatientDataset from "./upload-patient-dataset";
import PatientDetails from "./patient-details";
import CriteriaAiAssistedView from "./criteria-ai-assisted-view";
import CriteriaSqlQuery from "./criteria-sql-query";
import SaveCohort from "./save-cohort";

export const Modal = () => {
  const { modal } = useSelector((state: RootState) => state.ui);

  return (
    <>
      {modal[MODAL_TYPE.EDIT_PROFILE] && <EditProfile />}
      {modal[MODAL_TYPE.ADD_EDIT_PROJECT] && <AddEditProject />}
      {modal[MODAL_TYPE.DELETE_CONFIRMATION] && <DeleteConfirmation />}
      {modal[MODAL_TYPE.CRITERIA_CATEGORIES] && <CriteriaCategories />}
      {modal[MODAL_TYPE.FORGOT_PASSWORD] && <ForgotPassword />}
      {modal[MODAL_TYPE.UPLOAD_PATIENT_DATASET] && <UploadPatientDataset />}
      {modal[MODAL_TYPE.PATIENT_DETAILS] && <PatientDetails />}
      {modal[MODAL_TYPE.CRITERIA_AI_ASSISTED_VIEW] && <CriteriaAiAssistedView />}
      {modal[MODAL_TYPE.CRITERIA_SQL_QUERY] && <CriteriaSqlQuery />}
      {modal[MODAL_TYPE.SAVE_COHORT] && <SaveCohort />}
    </>
  );
};
