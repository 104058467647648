import { useEffect, useState } from "react";

export const useNctId = () => {
  const [nctId, setNctId] = useState("");
  const [nctIdValidationMessage, setNctIdValidationMessage] = useState("");
  const [isValidNctId, setIsValidNctId] = useState(true);

  useEffect(() => {
    const isValid = /^(NCT[0-9]{8})$/.test(nctId);
    if (isValid || !nctId) setNctIdValidationMessage("");
    else
      setNctIdValidationMessage(
        "NCT ID should be of the format - “NCT” followed by an 8-digit number (for example, NCT00000419)"
      );
    setIsValidNctId(isValid);
  }, [nctId]);

  return {
    nctId,
    setNctId,
    nctIdValidationMessage,
    isValidNctId,
  };
};
