import React, { useState } from "react";
import { Form, Input, Modal, Button } from "antd";
import { useForm } from "antd/lib/form/Form";
import { SaveCohortProps } from ".";
import { CloseOutlined } from "@ant-design/icons";
import { useSavedCohorts } from "../../../hooks";
import "../modal.scss";

const SaveCohort = (props: SaveCohortProps) => {
  const { visibility, handleClose, metaData, projectId } = props;
  const [form] = useForm();
  const { loading, saveCohort } = useSavedCohorts();
  const [disableSave, setDisableSave] = useState(true);
  const [error, setError] = useState("");

  const handleFormSubmit = async (e: any) => {
    setError("");
    const name = e.target[0].value;
    const description = e.target[1].value;
    if (projectId === -1) {
      setError("Please select a project to continue");
      return;
    }
    if (!name || !description) {
      setError("Name or description cannot be blank");
      return;
    }
    await saveCohort(projectId, name, description, metaData, handleClose);
  };

  const handleFormChange = () => {
    const hasErrors =
      !form.isFieldsTouched(true) || form.getFieldsError().some(({ errors }) => errors.length);
    setDisableSave(hasErrors);
  };

  return (
    <Modal
      className="autonomizeModal"
      centered
      width={587}
      visible={visibility}
      footer={null}
      closeIcon={<CloseOutlined onClick={handleClose} />}
    >
      <div className="addClient-title">
        <h1>Save Query</h1>
      </div>
      <div className="addClient-content">
        <Form
          className=""
          onSubmitCapture={(e) => handleFormSubmit(e)}
          name="save_cohort"
          onFieldsChange={handleFormChange}
          form={form}
        >
          <Form.Item
            name="name"
            rules={[{ required: true, message: "Please enter name for the cohort" }]}
          >
            <Input placeholder="Name" />
          </Form.Item>
          <Form.Item
            name="description"
            rules={[{ required: true, message: "Please enter description for the cohort" }]}
          >
            <Input placeholder="Description" />
          </Form.Item>
          {error && <h3 className="error">{error}</h3>}
          <Form.Item className="tr">
            <Button className="outline" htmlType="submit" loading={loading} disabled={disableSave}>
              Save Query
            </Button>
          </Form.Item>
        </Form>
      </div>
    </Modal>
  );
};

export default SaveCohort;
