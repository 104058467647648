import { c2qApiClient, apiClient } from ".";

const analyzeCriteriaForm = {
  nctid: "",
  dataset: "SynPUF 1K dataset",
  inc: "",
  exc: "",
  initialevent: "",
  rule: "false",
  ml: "false",
  abb: "false",
  recon: "false",
  obstart: "",
  obend: "",
  daysbefore: "0",
  daysafter: "0",
  limitto: "All",
};
const getPatientsForm = {
  dataset: "SynPUF 1K dataset",
  nctid: "",
  userID: "2056604124",
  time: "",
  exc: "",
  inc: "",
  initialevent: "[]",
  abb: "false",
  recon: "false",
  obstart: "",
  obend: "",
  daysbefore: "0",
  daysafter: "0",
  limitto: "All",
};

interface GetCohortCriteriaFromNctIdResponse {
  exc: string;
  gender: string;
  healthy_volunteers: string;
  inc: string;
  maxmum_age: string;
  minimum_age: string;
  sampling_method: string;
  study_pop: string;
}

interface AnalyzeCohortCriteriaResponse {
  display_exclude: AnalyzeInclusionExclusionCriteria[];
  display_include: AnalyzeInclusionExclusionCriteria[];
  queryResult: InclusionExclusionCriteriaPatientRecord[];
  display_initial_event: AnalyzeInclusionExclusionCriteria[];
  postgreSQL: string;
}

interface AnalyzeCriteriaResponse {
  display_exclude: IAnalyzeCriteria[];
  display_include: IAnalyzeCriteria[];
  requestId: string;
}

type GetCohortPatientsResponse = Pick<AnalyzeCohortCriteriaResponse, "queryResult">;

interface ICohortPatientsResponse {
  resultCount: number;
  queryResult: InclusionExclusionCriteriaPatientRecord[];
}

interface GetAllPatientsResponse {
  data: InclusionExclusionCriteriaPatientRecord[];
  status: string;
}

interface GetCohortPatientDetailsResponse {
  data: IPatient;
  status: string;
}

interface SqlQueryResponse {
  sql: string;
}

export interface IGetDocumentsRequest {
  limit?: number | null;
  offset: number;
}

const getCohortCriteriaFromNctId = async (nctId: string) => {
  const form = new FormData();
  form.append("nctid", nctId);
  const res = await c2qApiClient.post<GetCohortCriteriaFromNctIdResponse>("/ie/getct", form, {
    headers: { "Content-Type": "multipart/form-data" },
  });
  return res;
};

const getInclusionExclusionFromNctid = async (nctId: string) => {
  const res = await c2qApiClient.get<GetCohortCriteriaFromNctIdResponse>(
    `${nctId}/inclusion-exclusion`
  );
  return res;
};

const analyzeCohortCriteria = async (inclusion: string, exclusion: string, NCTID: string) => {
  const params = new URLSearchParams();
  Object.entries({ ...analyzeCriteriaForm, nctid: NCTID, inc: inclusion, exc: exclusion }).map(
    ([key, value]) => params.append(key, value)
  );
  const res = await c2qApiClient.post<AnalyzeCohortCriteriaResponse>("/main/runPipeline", params, {
    headers: { "Content-Type": "application/x-www-form-urlencoded; charset=UTF-8" },
  });
  return res;
};

const analyzeCriteria = async (inc: string, exc: string) => {
  const res = c2qApiClient.post<AnalyzeCriteriaResponse>("analyze", { inc, exc });
  return res;
};

const getCohortPatients = async (inclusion: string[], exclusion: string[], nctId: string) => {
  const params = new URLSearchParams();
  Object.entries({
    ...getPatientsForm,
    nctid: nctId,
    inc: JSON.stringify(inclusion.map((inc, index) => ({ id: index + 1, criterion: inc }))),
    exc: JSON.stringify(exclusion.map((exc, index) => ({ id: index + 1, criterion: exc }))),
    time: new Date().getTime().toString(),
  }).map(([key, value]) => params.append(key, value));
  const res = await c2qApiClient.post<GetCohortPatientsResponse>("/main/continueParsing", params, {
    headers: { "Content-Type": "application/x-www-form-urlencoded; charset=UTF-8" },
  });
  return res;
};

const getPatientsByRequestId = async (requestId: string) => {
  const res = await c2qApiClient.get<ICohortPatientsResponse>(`/patients/${requestId}`);
  return res;
};

const getCohortPatientDetails = async (patientId: string) => {
  const res = await apiClient.get<GetCohortPatientDetailsResponse>(
    `/person/medical-condition/${patientId}`
  );
  return res;
};

const getAllPatients = async () => {
  const res = await apiClient.get<GetAllPatientsResponse>("/person");
  return res;
};

const getSqlQuery = async (inclusion: string[], exclusion: string[], nctId: string) => {
  const params = new URLSearchParams();
  Object.entries({
    ...getPatientsForm,
    nctid: nctId,
    inc: JSON.stringify(inclusion.map((inc, index) => ({ id: index + 1, criterion: inc }))),
    exc: JSON.stringify(exclusion.map((exc, index) => ({ id: index + 1, criterion: exc }))),
    time: new Date().getTime().toString(),
  }).map(([key, value]) => params.append(key, value));
  const res = await c2qApiClient.get(`/main/generate-sql?sqlDialect=PostgreSQL`, { data: params });
  return res;
};

const getSqlQuery2 = async (requestId: string) => {
  const res = await c2qApiClient.get<SqlQueryResponse>(
    `/generate-sql/${requestId}?sqlDialect=PostgreSQL`
  );
  return res;
};

const getDocuments = async (projectId: number, reqObj: IGetDocumentsRequest) => {
  const res = await apiClient.get(
    `/document/list/${projectId}?limit=${reqObj.limit}&offset=${reqObj.offset}`
  );
  return res;
};

const deleteDocuments = async(documentId: number) => {
  const res = await apiClient.delete(`/document/${documentId}`);
  return res;
}

export const cohortService = {
  getCohortCriteriaFromNctId,
  analyzeCohortCriteria,
  getCohortPatients,
  getAllPatients,
  getCohortPatientDetails,
  getSqlQuery,

  getInclusionExclusionFromNctid,
  analyzeCriteria,
  getPatientsByRequestId,
  getSqlQuery2,
  getDocuments,
  deleteDocuments
};
