import React from 'react'
import ReactJson from "react-json-view";

interface Props {
  patientDetails : IPatient
}

function CohortPatientDetailsConatiner(props : Props) {
  
  const { patientDetails } = props;

  return (
    <div className='patient-details-conatiner'>
      <ReactJson collapsed={false} src={JSON.parse(JSON.stringify(patientDetails))} />
    </div>
  )
}

export default CohortPatientDetailsConatiner