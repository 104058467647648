import { useCallback, useState } from "react";
import { projectService } from "../api";
import { openNotificationWithIcon } from "../utils";

export const useSavedCohorts = () => {
  const [loading, setLoading] = useState(false);
  const [savedCohorts, setSavedCohorts] = useState([] as ISavedCohort[]);

  const saveCohort = useCallback(
    async (
      projectId: number,
      name: string,
      desc: string,
      metaData: ISaveCohortMetaData,
      successCallback?: () => void
    ) => {
      setLoading(true);
      try {
        const reqObj: ISaveCohortRequest = {
          createdBy: 1,
          description: desc,
          metaData: JSON.stringify(metaData),
          name,
          projectId,
        };
        await projectService.saveCohort(reqObj);
        openNotificationWithIcon("", "Query saved successfully", "success");
        successCallback && successCallback();
      } catch (err: any) {
        const res = err.response.data?.message || "Something went wrong, cannot save cohort";
        openNotificationWithIcon("", res, "error");
      } finally {
        setLoading(false);
      }
    },
    []
  );

  const getAllCohortsByProjectId = useCallback(async (projectId: number) => {
    setLoading(true);
    try {
      const { data } = await projectService.getSavedCohorts(projectId);
      setSavedCohorts(data.data.savedCohorts);
    } catch (err: any) {
      const res = err.response.data?.message || "Something went wrong, cannot get saved cohorts";
      openNotificationWithIcon("", res, "error");
    } finally {
      setLoading(false);
    }
  }, []);

  const deleteSavedCohort = useCallback(
    async (id: number, projectId: number) => {
      setLoading(true);
      try {
        const { data } = await projectService.deleteSaveCohort(id);
        await getAllCohortsByProjectId(projectId);
      } catch (err: any) {
        const res =
          err.response.data?.message || "Something went wrong, cannot delete saved cohorts";
        openNotificationWithIcon("", res, "error");
      } finally {
        setLoading(false);
      }
    },
    [getAllCohortsByProjectId]
  );

  return {
    savedCohorts,
    loading,
    deleteSavedCohort,
    saveCohort,
    getAllCohortsByProjectId,
  };
};
