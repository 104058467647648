import { apiClient } from ".";

interface IGetAllProjectsResponse {
  data: { projects: IProjectAPIResponse[] };
  status: string;
}

interface ICreateProjectResponse {
  data: IProjectAPIResponse;
  status: string;
}

interface IGetSavedCohortsResponse {
  data: IProjectWithSavedCohorts;
  status: string;
}

const getAll = async () => {
  const res = await apiClient.get<IGetAllProjectsResponse>("/project");
  return res;
};

const create = async (obj: IProjectCreate) => {
  const res = await apiClient.post<ICreateProjectResponse>("/project/create", obj);
  return res;
};

const update = async (obj: IProjectUpdate, projectId: number) => {
  const res = await apiClient.put(`/project/${projectId}`, obj);
  return res;
};

const getSavedCohorts = async (projectId: number) => {
  const res = await apiClient.get<IGetSavedCohortsResponse>(`/project/${projectId}/saved-cohorts`);
  return res;
};

const saveCohort = async (reqObj: ISaveCohortRequest) => {
  const res = await apiClient.post(`/saved-cohorts/create`, reqObj);
  return res;
};

const deleteSaveCohort = async (id: number) => {
  const res = await apiClient.put(`/saved-cohorts/delete/${id}`);
  return res;
};

export const projectService = {
  getAll,
  create,
  update,
  saveCohort,
  getSavedCohorts,
  deleteSaveCohort,
};
