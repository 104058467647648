import { Dispatch } from "redux";
import { connect } from "react-redux";
import { closeModal, RootState } from "../../../store";
import { MODAL_TYPE } from "../../../utils";
import CriteriaSqlQuery from "./CriteriaSqlQuery";

type StateProps = {
  visibility: boolean;
  postgreSQL: string;
};
type DispatchProps = {
  handleClose: () => void;
};

export type CriteriaSqlQueryProps = StateProps & DispatchProps;

const mapStateToProps = (state: RootState): StateProps => ({
  visibility: state.ui.modal[MODAL_TYPE.CRITERIA_SQL_QUERY],
  postgreSQL: state.cohort.postgreSQL,
});

const mapDispatchToProps = (dispatch: Dispatch): DispatchProps => ({
  handleClose: () => {
    dispatch(closeModal(MODAL_TYPE.CRITERIA_SQL_QUERY));
  },
});

export default connect(mapStateToProps, mapDispatchToProps)(CriteriaSqlQuery);
