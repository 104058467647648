import React from "react";
import { CloseOutlined } from "@ant-design/icons";
import { Modal } from "antd";
import { CriteriaSqlQueryProps } from ".";

const CriteriaSqlQuery = (props: CriteriaSqlQueryProps) => {
  const { visibility, postgreSQL, handleClose } = props;

  return (
    <Modal
      className="autonomizeModal addClient"
      centered
      visible={visibility}
      closeIcon={<CloseOutlined onClick={handleClose} />}
      footer={null}
    >
      <div className="addClient-title">
        <h1>View Query</h1>
      </div>
      <div
        className="addClient-content"
        dangerouslySetInnerHTML={{
          __html: postgreSQL
            .replaceAll(/\n/g, "<br>")
            .replaceAll(/\t/g, "&nbsp;&nbsp;&nbsp;&nbsp;"),
        }}
      />
      {/* <text>{postgreSQL}</text>
      </div> */}
    </Modal>
  );
};

export default CriteriaSqlQuery;
