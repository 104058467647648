import React, { useState } from "react";
import { Button, Input, Checkbox } from "antd";
import { CheckboxChangeEvent } from "antd/lib/checkbox";
import { Dropdown } from "../../components";
import { useNctId } from "../../hooks";
import { COUNTRY, DIVERSITY, STATES, THERAPEUTIC_AREA } from "./searchAsideData";
import "../../assets/sass/_checkbox.scss";

export const SearchAside = () => {
  const { nctId, nctIdValidationMessage, setNctId } = useNctId();
  const [therapeuticArea, setTherapeuticArea] = useState(THERAPEUTIC_AREA[0]);
  const [country, setCountry] = useState(COUNTRY[0]);
  const [state, setState] = useState(STATES[0]);
  const [diversity, setDiversity] = useState(DIVERSITY[0]);
  const [dctEligible, setDctEligible] = useState(false);
  const [partnerSitesSystem, setPartnerSitesSystem] = useState(false);

  return (
    <div className="flex flex-column gp">
      <h3 className="pb-0">Filter By</h3>
      <div className="flex flex-column gp">
        <div>
          <p className="select-field-title">Therapeutic Area</p>
          <Dropdown
            value={therapeuticArea.value}
            loading={false}
            onSelect={(value, option) => setTherapeuticArea(option)}
            options={THERAPEUTIC_AREA}
          />
        </div>
        <div>
          <p className="select-field-title">Country</p>
          <Dropdown
            value={country.value}
            loading={false}
            onSelect={(value, option) => setCountry(option)}
            options={COUNTRY}
          />
        </div>
        <div>
          <p className="select-field-title">States</p>
          <Dropdown
            value={state.value}
            loading={false}
            onSelect={(value, option) => setState(option)}
            options={STATES}
          />
        </div>
        <div>
          <p className="select-field-title">Diversity</p>
          <Dropdown
            value={diversity.value}
            loading={false}
            onSelect={(value, option) => setDiversity(option)}
            options={DIVERSITY}
          />
        </div>
      </div>
      {/* <h3 className="tc pb-0">OR</h3> */}
      <div>
        <p className="cohort-drawer-title">{`NCTID (Extract criteria from ClinicalTrials.gov)`}</p>
        <Input
          className={`extract-search`}
          placeholder="eg., NCT01640873"
          value={nctId}
          onChange={(e) => setNctId(e.target.value)}
        />
        {nctIdValidationMessage && <p className="error">{nctIdValidationMessage}</p>}
      </div>
      <div className="search-checkbox">
        <Checkbox onChange={(e: CheckboxChangeEvent) => setDctEligible(e.target.checked)}>
          DCT Eligible Participants
        </Checkbox>
        <Checkbox onChange={(e: CheckboxChangeEvent) => setPartnerSitesSystem(e.target.checked)}>
          Partner Sites & Systems
        </Checkbox>
      </div>
      <Button className="fill">Search</Button>
    </div>
  );
};
