import React from "react";
import { toTitleCase } from "../../../utils";

interface Props {
  data: string;
}

export const ListPatientCondition = ({ data }: Props) => {
  return (
    <>
      {data.split(" | ").length > 1 ? (
        <ol>
          {data.split(" | ").map((item, index) => (
            <li key={`patient-detail-item-${index}`} style={{ listStyleType: "decimal" }}>
              {item}
            </li>
          ))}
        </ol>
      ) : (
        <p>{data}</p>
      )}
    </>
  );
};

interface PatientDetailCriteriaProps {
  heading: string;
  content: { text: string; tag: string }[];
}

export const PatientDetailCriteriaSection = (props: PatientDetailCriteriaProps) => {
  const { content, heading } = props;
  return (
    <div>
      <h2 className="m-b">{heading}</h2>
      <ol style={{ listStyle: "inside" }}>
        {content.map((item, index) => (
          <li key={`patient-detail-item-${index}`} style={{ listStyleType: "decimal" }}>
            {item.text} <strong>[{toTitleCase(item.tag)}]</strong>
          </li>
        ))}
      </ol>
    </div>
  );
};
