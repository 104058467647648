import React, { useEffect, useState } from "react";
import { CohortContainer, PageWrapper } from "../containers";
import { useDispatch } from "react-redux";
import { resetCohort, updateCohortSelectedProject } from "../store";

interface Props {
  displaySelectProjectDropdown?: boolean;
  selectedProject?: IDropdownOption;
}

const Cohort = (props: Props) => {
  const { displaySelectProjectDropdown, selectedProject } = props;
  const dispatch = useDispatch();
  const [isDrawerCollapsed, setIsDrawerCollapsed] = useState(true);
  const [isCohortAsideCollapsed, setIsCohortAsideCollapsed] = useState(false);

  useEffect(() => {
    if (selectedProject) dispatch(updateCohortSelectedProject(selectedProject));
    return () => {
      dispatch(resetCohort());
    };
  }, [dispatch, selectedProject]);

  return (
    <PageWrapper
      containerClassName={isDrawerCollapsed ? "no-padding-top" : "no-padding"}
      pageContainerChildren={
        <CohortContainer
          {...{
            displaySelectProjectDropdown,
            isDrawerCollapsed,
            setIsDrawerCollapsed,
            isCohortAsideCollapsed,
            setIsCohortAsideCollapsed,
          }}
        />
      }
    />
  );
};

export default Cohort;
