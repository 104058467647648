import React, { useEffect, useState } from "react";
import { CohortPatientsExport, CohortPatientsTable, Loading } from "../../components";
import { Map } from "./search-container";
import { useFetchCohortPatients } from "../../hooks";
import { Button } from "antd";
import { ListButtonIcon, MapButtonIcon } from "../../assets/icons";

enum VIEW_STYLE {
  MAP,
  LIST,
}

export const SearchContainer = () => {
  const { fetchAllPatients, getAllPatientsLoading, patients } = useFetchCohortPatients();
  const [viewStyle, setViewStyle] = useState(VIEW_STYLE.LIST);

  useEffect(() => {
    fetchAllPatients();
  }, [fetchAllPatients]);

  const dataSource: ICohortPatientTableDataType[] = patients.map((item) => {
    const { age, birth_date, gender, person_id, race } = item;
    return {
      age,
      dob: birth_date,
      gender,
      key: `patient-${person_id}`,
      patientId: person_id,
      race,
    };
  });

  return (
    <div>
      {getAllPatientsLoading ? (
        <Loading />
      ) : (
        <div>
          <div className="flex jcsb aic m-b">
            <h3 className="pb-0">{`Matched Subjects: ${patients.length}`}</h3>
            <div className="flex gp-10 aic">
              <Button
                className={
                  viewStyle === VIEW_STYLE.MAP ? "fill flex gp-10 aic" : "outline flex gp-10 aic"
                }
                icon={<MapButtonIcon />}
                onClick={() => setViewStyle(VIEW_STYLE.MAP)}
              >
                Map
              </Button>
              <Button
                className={
                  viewStyle === VIEW_STYLE.LIST ? "fill flex gp-10 aic" : "outline flex gp-10 aic"
                }
                icon={<ListButtonIcon />}
                onClick={() => setViewStyle(VIEW_STYLE.LIST)}
              >
                List
              </Button>
              <CohortPatientsExport data={dataSource} />
            </div>
          </div>
          {viewStyle === VIEW_STYLE.LIST ? <CohortPatientsTable data={dataSource} /> : <Map />}
        </div>
      )}
    </div>
  );
};
