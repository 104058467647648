import React, { useCallback, useEffect, useMemo, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { Button, Input, Collapse } from "antd";
import { Dropdown } from "../../../components";
import {
  useFetchCohortCriteria,
  useFetchCohortPatients,
  useNctId,
  useProject,
} from "../../../hooks";
import {
  cohortSelectedProjectInitialState,
  resetCohort,
  RootState,
  setAnalyzed,
  updateCohortSelectedProject,
  updateNctId,
} from "../../../store";
import { CriteriaPanel } from "./CriteriaPanel";
import { AnalyseCriteriaPanel } from "./AnalyseCriteriaPanel";

const { Panel } = Collapse;

const Panels = {
  criteria: "Criteria",
  analyseCriteria: "AI Assisted View",
  none: "",
};

interface Props {
  displaySelectProjectDropdown?: boolean;
  isDrawerCollapsed: boolean;
  isAnalyzeCohortLoading: boolean;
  cohortAsideRef: React.MutableRefObject<any>;
  setIsDrawerCollapsed: React.Dispatch<React.SetStateAction<boolean>>;
  setIsAnalyzeCohortLoading: (loading: boolean) => void;
}

export const CohortDrawer = (props: Props) => {
  const {
    displaySelectProjectDropdown,
    cohortAsideRef,
    isDrawerCollapsed,
    isAnalyzeCohortLoading,
    setIsAnalyzeCohortLoading,
    setIsDrawerCollapsed,
  } = props;
  const dispatch = useDispatch();
  const { getProjects, isProjectsLoading } = useProject();
  const { fetchPatients } = useFetchCohortPatients();
  const { cohortCriteria, nctId, selectedProject, analyzeCohortCriteria } = useSelector(
    (state: RootState) => state.cohort
  );
  const projects = useSelector((state: RootState) => state.project.list);
  const {
    fetchCohortCriteriaError,
    fetchCohortCriteriaFromNctId,
    isCohortCriteriaLoading,
    setFetchCohortCriteriaError,
  } = useFetchCohortCriteria();

  const {
    isValidNctId,
    nctId: localNctId,
    nctIdValidationMessage,
    setNctId: setLocalNctId,
  } = useNctId();
  const [selectedProjectError, setSelectedProjectError] = useState("");
  const [activePanel, setActivePanel] = useState(
    !analyzeCohortCriteria.inclusion && !analyzeCohortCriteria.exclusion
      ? Panels.criteria
      : Panels.analyseCriteria
  );
  const projectOptions = useMemo(() => {
    const options: IDropdownOption[] = [cohortSelectedProjectInitialState];
    projects.map((item) => options.push({ value: item.name, id: item.id }));
    return options;
  }, [projects]);

  const onProjectSelected = useCallback(
    (value: string, option: IDropdownOption) => {
      dispatch(updateCohortSelectedProject(option));
      setSelectedProjectError("");
    },
    [dispatch]
  );

  useEffect(() => {
    if (nctId) setLocalNctId(nctId);
  }, [nctId, setLocalNctId]);

  useEffect(() => {
    if (displaySelectProjectDropdown) getProjects();
  }, [displaySelectProjectDropdown, getProjects]);

  const onExtractCriteriaSearch = useCallback(
    async (str: string) => {
      setActivePanel(Panels.criteria);
      await fetchCohortCriteriaFromNctId(str);
    },
    [fetchCohortCriteriaFromNctId]
  );

  const onResetClick = useCallback(() => {
    dispatch(resetCohort());
    setLocalNctId("");
    setFetchCohortCriteriaError("");
  }, [dispatch, setFetchCohortCriteriaError, setLocalNctId]);

  const onAnalyzeCohortClick = useCallback(async () => {
    setIsAnalyzeCohortLoading(true);
    const { exclusion, inclusion } = cohortCriteria;
    let updatedNctId = "";
    if (isValidNctId) updatedNctId = localNctId;
    dispatch(updateNctId(updatedNctId));
    dispatch(setAnalyzed(true));
    // setIsDrawerCollapsed(true);
    await fetchPatients(localNctId, inclusion, exclusion, true);
    setActivePanel(Panels.analyseCriteria);
    setIsAnalyzeCohortLoading(false);
  }, [
    setIsAnalyzeCohortLoading,
    cohortCriteria,
    isValidNctId,
    localNctId,
    dispatch,
    fetchPatients,
  ]);

  return (
    <>
      {
        <div
          ref={cohortAsideRef}
          className={`${
            displaySelectProjectDropdown ? "cohort-max-height" : ""
          } cohort-drawer basic-detail-content flex flex-column gp no-wrap`}
        >
          {displaySelectProjectDropdown && (
            <div>
              <p className="cohort-drawer-title">Select Project</p>
              <div className="ai-select-box">
                <Dropdown
                  value={selectedProject.value}
                  options={projectOptions}
                  loading={isProjectsLoading}
                  onSelect={onProjectSelected}
                />
              </div>
              {selectedProjectError && <p className="error">{selectedProjectError}</p>}
            </div>
          )}
          <div>
            <p className="cohort-drawer-title">{`NCTID (Extract criteria from ClinicalTrials.gov)`}</p>
            <div className="flex no-wrap gp-10 aic">
              <Input
                className={`extract-search`}
                placeholder="eg., NCT01640873"
                value={localNctId}
                onChange={(e) => setLocalNctId(e.target.value)}
                onPressEnter={() => onExtractCriteriaSearch(localNctId)}
              />
              <Button
                className="outline"
                disabled={!isValidNctId}
                loading={isCohortCriteriaLoading}
                onClick={() => onExtractCriteriaSearch(localNctId)}
              >
                Extract
              </Button>
            </div>
            {nctIdValidationMessage && <p className="error">{nctIdValidationMessage}</p>}
            {fetchCohortCriteriaError && <p className="error">{fetchCohortCriteriaError}</p>}
          </div>
          <Collapse
            className="accordian"
            accordion
            activeKey={[activePanel]}
            expandIconPosition="end"
            onChange={(e) => {
              if (!e) setActivePanel(Panels.none);
              if (typeof e === "string") setActivePanel(e);
            }}
          >
            <Panel
              className="basic-detail-content m-b"
              header={Panels.criteria}
              key={Panels.criteria}
            >
              <CriteriaPanel
                {...{
                  isAnalyzeCohortLoading,
                  onAnalyzeCohortClick,
                  onResetClick,
                }}
              />
            </Panel>
            <Panel
              className="basic-detail-content"
              header={Panels.analyseCriteria}
              key={Panels.analyseCriteria}
            >
              <AnalyseCriteriaPanel
                {...{
                  isAnalyzeCohortLoading,
                  setIsAnalyzeCohortLoading,
                  displaySelectProjectDropdown,
                  cohortAsideRef,
                  setSelectedProjectError,
                  setIsDrawerCollapsed,
                }}
              />
            </Panel>
          </Collapse>
        </div>
      }
    </>
  );
};
