import React from "react";
import { useDispatch } from "react-redux";
import { Space, Typography } from "antd";
import { Mark } from "../../../components";
import { openModal } from "../../../store";
import { MODAL_TYPE } from "../../../utils";

const { Text } = Typography;

export const AnalyzeCriteriaInstructions = () => {
  const dispatch = useDispatch();

  return (
    <div className="basic-detail-content p-20">
      <Space direction="vertical" className="lh-2">
        <h4 className="mb-0">Criteria Parsing Result</h4>
        <Text strong>Please note:</Text>
        <Text>
          1. Only those criteria with a check on the first column are used to query for potential
          patients you see in your cohort result. You can select or unselect a criterion by
          checking/unchecking the box to modify the query.
        </Text>
        <Text>
          2. Medical terms recognized by the system are highlighted in different colored tags. The
          color indicates the category of a medical term{" "}
          <span
            className="cursor-pointer medical-term"
            onClick={() => dispatch(openModal(MODAL_TYPE.CRITERIA_CATEGORIES))}
          >
            (click to see examples)
          </span>{" "}
          .You can edit the parsing results by adding, updating, deleting, or clearing all tags.
        </Text>
        <Text>
          3. Negation cue indicates the scope within a sentence where the phrase needs to be
          negated.
        </Text>
        <Text>
          4. The system will analyze the sentence structure and automatically link concepts to their
          corresponding
          <Mark text="temporal terms" backgroundColor="#95CB7A" categoryText="TEMPORAL" /> and the{" "}
          <Mark text="measurement" backgroundColor="#E8AE55" categoryText="MEASUREMENT" /> to their
          corresponding <Mark text="values" backgroundColor="#505EDA" categoryText="VALUES" />
        </Text>
      </Space>
    </div>
  );
};
