import React from "react";
import Table, { ColumnsType } from "antd/lib/table";
import { useSelector } from "react-redux";
import { RootState } from "../../../store";
import { getLocalDateTimeStringFromISO } from "../../../utils";
import { ProjectActions } from ".";
import { CheckCircleFilled, CloseCircleFilled } from "@ant-design/icons";

interface DataType {
  key: React.Key;
  projectId: number;
  projectName: string;
  createdOn: string;
  matchedPatients: number;
  action: string;
  status: boolean;
  project: IProject;
  private: boolean;
}

const getColumns = (): ColumnsType<DataType> => [
  {
    title: "Project Name",
    dataIndex: "projectName",
    ellipsis: {
      showTitle: true,
    },
  },
  {
    title: "Total Patients",
    dataIndex: "matchedPatients",
  },
  {
    title: "Created On",
    dataIndex: "createdOn",
  },
  {
    title: "Private",
    dataIndex: "private",
    render: (value, record) => (
      record.private ?
        (<div>
          <CheckCircleFilled className="check-icon" />
        </div>) :
        (
          <div>
            <CloseCircleFilled className="close-icon" />
          </div>
        )
    )
  },
  {
    title: "Action",
    dataIndex: "action",
    render: (value, record) => <ProjectActions record={record.project} displayViewMore />,
  },
];

export const ProjectsListInTable = () => {
  const { list: projectsList } = useSelector((state: RootState) => state.project);
  const dataSource: DataType[] = projectsList.map((item, index) => ({
    action: "",
    createdOn: getLocalDateTimeStringFromISO(item.createdAt),
    key: `project-list-item-${index}`,
    matchedPatients: 1000,
    projectId: item.id,
    projectName: item.name,
    status: item.isActive,
    project: item,
    private: item.private
  }));

  return (
    <Table
      className="basic-detail-content m-t"
      columns={getColumns()}
      dataSource={dataSource}
      pagination={false}
    />
  );
};
