import { CRITERIAS, NEGATION, capitalizeFirstLetter } from "../../utils";
import * as XLSX from "xlsx";

export const downloadFile = ({
  data,
  fileName,
  fileType,
}: {
  data: string;
  fileName: string;
  fileType: string;
}) => {
  // Create a blob with the data we want to download as a file
  const blob = new Blob([data], { type: fileType });
  // Create an anchor element and dispatch a click event on it to trigger a download
  const a = document.createElement("a");
  a.download = fileName;
  a.href = window.URL.createObjectURL(blob);
  const clickEvt = new MouseEvent("click", {
    view: window,
    bubbles: true,
    cancelable: true,
  });
  a.dispatchEvent(clickEvt);
  a.remove();
};

export const exportToCsv = (cohortValue: ICohortPatientTableDataType[], delimeter: string) => {
  const columns = `Patient ID${delimeter}DOB${delimeter}Age${delimeter}Gender${delimeter}Race`;
  const headers = [columns];
  const cohortDataCsv = cohortValue.reduce((data: string[], cohortData) => {
    data.push(
      [
        cohortData.patientId,
        cohortData.dob,
        cohortData.age,
        cohortData.gender,
        cohortData.race,
      ].join(delimeter)
    );
    return data;
  }, []);

  downloadFile({
    data: [...headers, ...cohortDataCsv].join("\n"),
    fileName: "result.csv",
    fileType: "text/csv",
  });
};

const prepareData = (
  inclusions: IAnalyzeSelectedInclusionExclusionCriteria[],
  exclusions: IAnalyzeSelectedInclusionExclusionCriteria[],
  delimeter: string
) => {
  let i = 1;
  const csvData: string[] = [];
  inclusions.forEach((inclusion) => {
    if (inclusion.concepts.length !== 0) {
      inclusion.concepts.forEach((concept) => {
        csvData.push(
          [
            i++,
            inclusion.text,
            CRITERIAS.INCLUSION_CRITERIA,
            concept.criterion_text,
            concept.data_entity.toLowerCase().includes(NEGATION)
              ? capitalizeFirstLetter(NEGATION)
              : capitalizeFirstLetter(concept.data_entity),
            concept.snomed_code,
            concept.hspcs_code,
            concept.loinc_code,
            concept.icd_code,
            concept.icd_confidence_score,
            concept.icd_description,
            concept.rxnorm_code,
            concept.rxnorm_confidence_score,
            concept.rxnorm_description,
            concept.data_entity.toLowerCase().includes(NEGATION) ? "Yes" : "",
          ].join(delimeter)
        );
      });
    }
  });
  exclusions.forEach((exclusion) => {
    if (exclusion.concepts.length !== 0) {
      exclusion.concepts.forEach((concept) => {
        csvData.push(
          [
            i++,
            exclusion.text,
            CRITERIAS.EXCLUSION_CRITERIA,
            concept.criterion_text,
            concept.data_entity.toLowerCase().includes(NEGATION)
              ? capitalizeFirstLetter(NEGATION)
              : capitalizeFirstLetter(concept.data_entity),
            concept.snomed_code,
            concept.hspcs_code,
            concept.loinc_code,
            concept.icd_code,
            concept.icd_confidence_score,
            concept.icd_description,
            concept.rxnorm_code,
            concept.rxnorm_confidence_score,
            concept.rxnorm_description,
            concept.data_entity.toLowerCase().includes(NEGATION) ? "Yes" : "",
          ].join(delimeter)
        );
      });
    }
  });

  return csvData;
};

export const exportInclusionsExclusionsToCSV = (
  inclusions: IAnalyzeSelectedInclusionExclusionCriteria[],
  exclusions: IAnalyzeSelectedInclusionExclusionCriteria[],
  delimeter: string,
  filename: string
) => {
  const columns = `S. No.${delimeter}Criteria Text${delimeter}Criteria Type${delimeter}Entity Text${delimeter}Entity Type${delimeter}SNOMED Code${delimeter}HSPCS Code${delimeter}LONIC Code${delimeter}ICD Code${delimeter}ICD Confidence Score${delimeter}ICD Description${delimeter}RXNORM Code${delimeter}RXNORM Confidence Score${delimeter}RXNORM Description${delimeter}Negation`;
  const headers = [columns];
  const csvData = prepareData(inclusions, exclusions, delimeter);
  downloadFile({
    data: [...headers, ...csvData].join("\n"),
    fileName: filename,
    fileType: "text/csv",
  });
};

export const downloadDataAsXlsx = (
  inclusions: IAnalyzeSelectedInclusionExclusionCriteria[],
  exclusions: IAnalyzeSelectedInclusionExclusionCriteria[],
  delimeter: string,
  fileName: string
) => {
  const data = prepareData(inclusions, exclusions, delimeter);
  const headers = [
    "S. No.",
    "Criteria Text",
    "Criteria Type",
    "Entity Text",
    "Entity Type",
    "SNOMED Code",
    "HSPCS Code",
    "LONIC Code",
    "ICD Code",
    "ICD Confidence Score",
    "ICD Description",
    "RXNORM Code",
    "RXNORM Confidence Score",
    "RXNORM Description",
    "Negation",
  ];
  const excelData = data.map((item) => {
    const values = item.split(delimeter);
    const obj: { [key: string]: string } = {};
    headers.forEach((header, index) => {
      obj[header] = values[index];
    });
    return obj;
  });
  const worksheet = XLSX.utils.json_to_sheet(excelData);
  const workbook = XLSX.utils.book_new();
  XLSX.utils.book_append_sheet(workbook, worksheet, "Sheet1");

  XLSX.writeFile(workbook, fileName);
};
