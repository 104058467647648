import React, { useEffect, useState } from "react";
import { Loading } from "../../../../components";
import TasksApi from "../../../../api/tasks.api";
import { openNotificationWithIcon } from "../../../../utils";
import CopyToClipboard from "react-copy-to-clipboard";
import { Button, UploadFile } from "antd";
import { ClipboardIcon } from "../../../../assets/icons";
import { DownloadOutlined } from "@ant-design/icons";
import { useSelector } from "react-redux";
import { RootState } from "../../../../store";

interface Props {
  isLoading: boolean;
  setIsLoading: React.Dispatch<React.SetStateAction<boolean>>;
  query: string;
  viewFile: UploadFile<any>[];
  documentId: number;
}

interface Criteria {
  inclusion: string[];
  exclusion: string[];
}

export const GenerateQuestionnaire = ({
  isLoading,
  setIsLoading,
  query,
  viewFile,
  documentId,
}: Props) => {
  const [queryResponse, setQueryResponse] = useState("");
  const { documentName } = useSelector((state: RootState) => state.document);
  function parseCriteriaText(text: string): Criteria {
    const criteria: Criteria = { inclusion: [], exclusion: [] };
    let currentSection: "inclusion" | "exclusion" | null = null;
    text.split("\n").forEach((line) => {
      if (line.startsWith("Title:")) {
        currentSection = line.includes("Inclusion") ? "inclusion" : "exclusion";
      } else if (line.trim().length > 0) {
        if (currentSection === "inclusion") {
          criteria.inclusion.push(line);
        } else if (currentSection === "exclusion") {
          criteria.exclusion.push(line);
        }
      }
    });
    return criteria;
  }

  useEffect(() => {
    const getTask = async () => {
      try {
        if (documentId > 0) {
          setIsLoading?.(true);
          const { data } = await new TasksApi().generateQuestionnaire(documentId);

          setQueryResponse(data.data?.["content"] || ""); // add a check for data
        }
      } catch (e: any) {
        openNotificationWithIcon("", e.response?.data?.message || "An error occurred", "error");
        setQueryResponse("");
      } finally {
        setIsLoading(false);
      }
    };
    getTask();
  }, [documentId, setIsLoading]);

  function convertToCSV(criteria: Criteria): string {
    let csv = "Inclusion Criteria Questions,Exclusion Criteria Questions\n";

    const maxLength = Math.max(criteria.inclusion.length, criteria.exclusion.length);

    for (let i = 0; i < maxLength; i++) {
      const inclusionQuestion = criteria.inclusion[i] || "";
      const exclusionQuestion = criteria.exclusion[i] || "";
      csv += `"${inclusionQuestion.replace(/"/g, '""')}","${exclusionQuestion.replace(
        /"/g,
        '""'
      )}"\n`;
    }

    return csv;
  }

  function downloadAsCsv() {
    const criteria = parseCriteriaText(queryResponse);
    const csvContent = convertToCSV(criteria);
    const blob = new Blob([csvContent], { type: "text/csv;charset=utf-8;" });
    const url = URL.createObjectURL(blob);
    const link = document.createElement("a");
    link.setAttribute("href", url);
    link.setAttribute("download", "criteria_questions.csv");
    document.body.appendChild(link);
    link.click();
    document.body.removeChild(link);
  }

  return (
    <>
      <h1 style={{ marginBottom: "10px" }}>{documentName}</h1>
      <div style={{ display: "flex" }}>
        <div></div>
        <h3 className="m-b" style={{ margin: "auto auto auto 0" }}>
          Result
        </h3>
        {!isLoading && queryResponse && (
          <div>
            <DownloadOutlined
              onClick={downloadAsCsv}
              title="Export to CSV"
              style={{ fontSize: "21px", marginRight: "1em" }}
            />
            {/* <Button className="outline" onClick={downloadAsCsv}>Download As CSV</Button> */}
            <CopyToClipboard
              text={queryResponse}
              onCopy={() => openNotificationWithIcon("", "Text Copied", "success")}
            >
              {/* <Button className="outline">Copy to Clipboard</Button> */}
              <ClipboardIcon className="copyIcon" />
            </CopyToClipboard>
          </div>
        )}
        {/* <ClipboardIcon onClick={handleCopyButtonClick}></ClipboardIcon> */}
      </div>
      <br />
      {isLoading ? (
        <Loading />
      ) : queryResponse ? (
        <div>
          {/* <div className="query-response" style={{ whiteSpace: "pre-line" }}>{queryResponse}</div> */}
          {/* <div className="tr m-t">
          </div> */}
          <br />
          {parseCriteriaText(queryResponse)?.inclusion[0] ===
            "Inclusion criteria for this file could not be extracted properly." &&
          parseCriteriaText(queryResponse)?.exclusion[0] ===
            "Exclusion criteria for this file could not be extracted properly." ? (
            <div>{`We did not pick up any Inclusion/Exclusion Criteria in this document. Please try a different protocol document`}</div>
          ) : (
            <div>
              <h3>Inclusion Criteria Questions</h3>
              {parseCriteriaText(queryResponse)?.inclusion.map((item, index) => (
                <div key={index} style={{ whiteSpace: "pre-line" }}>
                  {item}
                </div>
              ))}
              <br />
              <h3>Exclusion Criteria Questions</h3>
              {parseCriteriaText(queryResponse)?.exclusion.map((item, index) => (
                <div key={index} style={{ whiteSpace: "pre-line" }}>
                  {item}
                </div>
              ))}
            </div>
          )}
          {/* <div className="query-response" style={{ whiteSpace: "pre-line" }}>{parseCriteriaText(queryResponse[0])?.exclusion || ''}</div> */}
          {/* <CopyToClipboard text={queryResponse} onCopy={() => openNotificationWithIcon("", "Text Copied", "success")}> */}
          {/* <Button className="outline">Copy to Clipboard</Button> */}
          {/* <ClipboardIcon></ClipboardIcon> */}
          {/* </CopyToClipboard> */}
        </div>
      ) : (
        <div>No questionnaire found for the document id</div>
      )}
    </>
  );
};
