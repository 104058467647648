import { CloseOutlined } from "@ant-design/icons";
import { Button, Modal, Table } from "antd";
import type { ColumnsType } from "antd/es/table";
import React from "react";
import { Mark } from "../../../components";
import { CriteriaCategoriesProps } from ".";
import { CriteriaCategory, CRITERIA_CATEGORIES } from "../../../utils";

interface DataType {
  key: React.Key;
  category: string;
  tag: CriteriaCategory;
  medicalTerm: string;
  mappedConcept: string;
}

const columns: ColumnsType<DataType> = [
  {
    title: "Category",
    dataIndex: "category",
    key: "category",
  },

  {
    title: "Tag",
    dataIndex: "tag",
    key: "tag",
    render: (value) =>( //record) => (
      <>
        {value.medical_term.split(";").map((medicalTerm: string, i: number) => {
          return (
            <Mark
              key={i}
              text={medicalTerm}
              styledText={
                value.hasConceptCategory ? (
                  <b>
                    <i>{`[${value.mapped_concept}]`}</i>
                  </b>
                ) : (
                  <></>
                )
              }
              backgroundColor={value.color}
              categoryText={value.name.split(" ").join("_").toUpperCase()}
            />
          );
        })}
      </>
    ),
  },
  {
    title: "Medical Term",
    dataIndex: "medicalTerm",
    key: "medicalTerm",
  },
  {
    title: "Mapped Concept",
    dataIndex: "mappedConcept",
    key: "mappedConcept",
  },
];

const CriteriaCategories = ({ visibility, handleClose }: CriteriaCategoriesProps) => {
  const dataSource: DataType[] = CRITERIA_CATEGORIES.map(
    (item: CriteriaCategory, index: number) => ({
      category: item.name,
      tag: item,
      medicalTerm: item.medical_term,
      mappedConcept: item.hasConceptCategory ? item.mapped_concept : "N/A",
      key: `criteria-category-${index}`,
    })
  );

  return (
    <Modal
      className="autonomizeModal addClient"
      centered
      // width={690}
      visible={visibility}
      closeIcon={<CloseOutlined onClick={handleClose} />}
      footer={null}
    >
      <div className="addClient-title">
        <h1>Categories of Medical Terms</h1>
      </div>
      <div className="addClient-content">
        <Table
          className="criteria-categories-table"
          columns={columns}
          dataSource={dataSource}
          // bordered
          pagination={false}
        ></Table>
        <div className="flex jcsb aic gp modal-footer m-t">
          <p>
            * Terms under these four categories do not have nor need to be mapped a concept due to
            their semantics.
          </p>
          <Button className="modal-close-button outline" onClick={handleClose}>
            Close
          </Button>
        </div>
      </div>
    </Modal>
  );
};

export default CriteriaCategories;
