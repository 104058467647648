import React, { useEffect } from "react";
import Table, { ColumnsType } from "antd/lib/table";
import { useFetchCohortPatients, usePagination } from "../../../hooks";
// import { patients } from "./projectPatientsData";
import { AppPagination, CohortPatientsExport, CohortPatientsTable } from "../../../components";
import { Input } from "antd";
import "./project-patients-details.scss";

const { Search } = Input;

export const ProjectPatientsList = () => {
  // const { onPaginationChange, page, pageSize, pageSizeOptions } = usePagination();
  const { fetchAllPatients, patients, getAllPatientsLoading } = useFetchCohortPatients();

  useEffect(() => {
    fetchAllPatients();
  }, [fetchAllPatients]);

  const dataSource: ICohortPatientTableDataType[] = patients.map((item) => {
    const { age, birth_date, gender, person_id, race } = item;
    return {
      age,
      dob: birth_date,
      gender,
      key: `patient-${person_id}`,
      patientId: person_id,
      race,
    };
  });

  return (
    <div className="matched-patient">
      <div className="flex aic jcsb">
        <h5>{`Total Subjects: ${patients.length}`}</h5>
        <div className="flex gp aic">
          {/* <Search
            className="record-search"
            placeholder="Search patients"
            style={{ width: "307px" }}
          /> */}
          <CohortPatientsExport data={dataSource} />
        </div>
      </div>
      <CohortPatientsTable data={dataSource} loading={getAllPatientsLoading} />
      {/* <div className="tr">
        <AppPagination
          {...{
            showSizeChanger: true,
            current: page,
            total: patients.length,
            pageSize,
            pageSizeOptions,
            onChange: onPaginationChange,
          }}
        />
      </div> */}
    </div>
  );
};
