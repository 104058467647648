import React from "react";
import { TextEditor } from "../../../components";

interface Props {
  description: string;
}

export const ProjectDetailOverview = ({ description }: Props) => {
  return (
    <div className="study-desc h-full">
      <h3 className="basic-detail-title">Study Description</h3>
      <div className="basic-detail-content">
        <TextEditor isDisabled text={description} />
      </div>
    </div>
  );
};
