import React from "react";
import { Routes, useLocation, Route } from "react-router-dom";
import { GuestLayout } from "./GuestLayout";
import { PrivateLayout } from "./PrivateLayout";
import { PAGE_URL } from "../utils";
import * as Pages from "../pages";

export const AppRoutes = () => {
  const location = useLocation();

  return (
    <Routes location={location}>
      <Route element={<PrivateLayout />}>
        <Route path={PAGE_URL.PROJECTS} element={<Pages.Projects />} />
        <Route path={`${PAGE_URL.PROJECT_DETAIL}/:projectId`} element={<Pages.ProjectDetail />} />
        <Route path={PAGE_URL.COHORT} element={<Pages.Cohort displaySelectProjectDropdown />} />
        <Route path={PAGE_URL.COHORT_PATIENTS} element={<Pages.CohortPatients />} />
        {/* <Route path={`${PAGE_URL.COHORT_PATIENTS}/:patientId`} element={<Pages.CohortPatientDetails/>} /> */}
        <Route path={PAGE_URL.SEARCH} element={<Pages.Search />} />
      </Route>
      <Route element={<GuestLayout />}>
        <Route path={PAGE_URL.LOGIN} element={<Pages.Login />} />
        <Route path={PAGE_URL.RESET_PASSWORD} element={<Pages.ResetPassword.Form />} />
        <Route path={PAGE_URL.RESET_PASSWORD_SUCCESS} element={<Pages.ResetPassword.Success />} />
        <Route path={PAGE_URL.CREATE_PASSWORD} element={<Pages.CreatePassword.Form />} />
        <Route path={PAGE_URL.CREATE_PASSWORD_SUCCESS} element={<Pages.CreatePassword.Success />} />
      </Route>
    </Routes>
  );
};
