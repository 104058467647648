import React from "react";
import { Dispatch } from "redux";
import { connect } from "react-redux";
import { Modal } from "antd";
import { CloseOutlined } from "@ant-design/icons";
import { EditProfileForm } from "./EditProfileForm";
import { closeModal, RootState } from "../../../store";
import { MODAL_TYPE, USER_ROLES_CODE } from "../../../utils";

type StateProps = {
  visibility: boolean;
};
// eslint-disable-next-line @typescript-eslint/no-unused-vars
const neverUSed = USER_ROLES_CODE
type DispatchProps = {
  handleClose: () => void;
};
type Props = StateProps & DispatchProps;

const EditProfile = ({ visibility, handleClose }: Props) => {
  return (
    <Modal
      className="autonomizeModal addClient edit-profile"
      centered
      width={587}
      title=""
      visible={visibility}
      footer={null}
      closeIcon={<CloseOutlined onClick={handleClose} />}
    >
      <EditProfileForm onClose={handleClose} />
    </Modal>
  );
};

const mapStateToProps = (state: RootState): StateProps => {
  return {
    visibility: state.ui.modal[MODAL_TYPE.EDIT_PROFILE],
  };
};

const mapDispatchToProps = (dispatch: Dispatch): DispatchProps => ({
  handleClose: () => {
    dispatch(closeModal(MODAL_TYPE.EDIT_PROFILE));
  },
});

export default connect(mapStateToProps, mapDispatchToProps)(EditProfile);
