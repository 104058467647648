import { createSlice, PayloadAction } from "@reduxjs/toolkit";

export const documentSliceInitialState: IDocument = {
  documentName: "",
};

export const documentSlice = createSlice({
  name: "documentSlice",
  initialState: documentSliceInitialState,
  reducers: {
    updateDocumentName: (state: IDocument, { payload }: PayloadAction<string>) => {
      state.documentName = payload;
    },
  },
});

export const { updateDocumentName } = documentSlice.actions;

export default documentSlice.reducer;
