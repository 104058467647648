export interface Criteria {
  inclusion: string[];
  exclusion: string[];
}

export function convertToCSV(criteria: Criteria): string {
  let csv = "Inclusion Criteria,Exclusion Criteria\n";

  const maxLength = Math.max(criteria.inclusion.length, criteria.exclusion.length);

  for (let i = 0; i < maxLength; i++) {
    const inclusionQuestion = criteria.inclusion[i] || "";
    const exclusionQuestion = criteria.exclusion[i] || "";
    csv += `"${inclusionQuestion.replace(/"/g, '""')}","${exclusionQuestion.replace(
      /"/g,
      '""'
    )}"\n`;
  }

  return csv;
}

export function parseCriteriaText(text: string): Criteria {
  const criteria: Criteria = { inclusion: [], exclusion: [] };
  let currentSection: "inclusion" | "exclusion" | null = null;
  text.split("\n").forEach((line) => {
    if (line.startsWith("Title:")) {
      currentSection = line.includes("Inclusion") ? "inclusion" : "exclusion";
    } else if (line.includes("INCLUSION CRITERIA")) {
      currentSection = "inclusion";
    } else if (line.includes("EXCLUSION CRITERIA")) {
      currentSection = "exclusion";
    } else if (line.trim().length > 0) {
      if (currentSection === "inclusion") {
        criteria.inclusion.push(line.replace(/^\d+\.\s*/, ""));
      } else if (currentSection === "exclusion") {
        criteria.exclusion.push(line.replace(/^\d+\.\s*/, ""));
      }
    }
  });

  return criteria;
}
