import { Dispatch } from "redux";
import { connect } from "react-redux";
import AddEditProject from "./AddEditProject";
import { closeModal, openModal, RootState, setProjectEditDelete } from "../../../store";
import { MODAL_TYPE } from "../../../utils";

type StateProps = {
  visibility: boolean;
  isEdit: boolean;
  editObj: IProject;
};
type DispatchProps = {
  handleClose: () => void;
  openUploadPatientDataset: () => void;
};

export type AddEditProjectProps = StateProps & DispatchProps;

const mapStateToProps = (state: RootState): StateProps => {
  const isEdit = state.project.isEdit;
  const id = state.project.id;
  let editObj = {} as IProject;
  if (isEdit && id !== -1) {
    const obj = state.project.list.find((item) => item.id === id);
    if (obj) editObj = obj;
  }
  return {
    visibility: state.ui.modal[MODAL_TYPE.ADD_EDIT_PROJECT],
    isEdit,
    editObj,
  };
};

const mapDispatchToProps = (dispatch: Dispatch): DispatchProps => ({
  handleClose: () => {
    dispatch(setProjectEditDelete({ id: -1, isEdit: false }));
    dispatch(closeModal(MODAL_TYPE.ADD_EDIT_PROJECT));
  },
  openUploadPatientDataset: () => {
    dispatch(closeModal(MODAL_TYPE.ADD_EDIT_PROJECT));
    dispatch(openModal(MODAL_TYPE.UPLOAD_PATIENT_DATASET));
  },
});

export default connect(mapStateToProps, mapDispatchToProps)(AddEditProject);
