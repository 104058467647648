import { Dispatch } from "redux";
import { connect } from "react-redux";
import {
  closeModal,
  RootState,
  updateSelectedAnalyzeExclusionCriteria,
  updateSelectedAnalyzeInclusionCriteria,
} from "../../../store";
import { MODAL_TYPE } from "../../../utils";
import CriteriaAiAssistedView from "./CriteriaAiAssistedView";

type StateProps = {
  visibility: boolean;
  nctId: string;
  analyzeInclusionCriteria: IAnalyzeSelectedInclusionExclusionCriteria[];
  analyzeExclusionCriteria: IAnalyzeSelectedInclusionExclusionCriteria[];
};
type DispatchProps = {
  handleClose: () => void;
  updateSelectedAnalyzeInclusionCriteria: (selectedInclusionCriteria: number[]) => void;
  updateSelectedAnalyzeExclusionCriteria: (selectedExclusionCriteria: number[]) => void;
};

export type CriteriaAiAssistedViewProps = StateProps & DispatchProps;

const mapStateToProps = (state: RootState): StateProps => ({
  visibility: state.ui.modal[MODAL_TYPE.CRITERIA_AI_ASSISTED_VIEW],
  nctId: state.cohort.nctId,
  analyzeExclusionCriteria: state.cohort.analyzeCohortCriteria.exclusion,
  analyzeInclusionCriteria: state.cohort.analyzeCohortCriteria.inclusion,
});

const mapDispatchToProps = (dispatch: Dispatch): DispatchProps => ({
  handleClose: () => {
    dispatch(closeModal(MODAL_TYPE.CRITERIA_AI_ASSISTED_VIEW));
  },
  updateSelectedAnalyzeInclusionCriteria: (selectedInclusionCriteria: number[]) => {
    dispatch(updateSelectedAnalyzeInclusionCriteria(selectedInclusionCriteria));
  },
  updateSelectedAnalyzeExclusionCriteria: (selectedExclusionCriteria: number[]) => {
    dispatch(updateSelectedAnalyzeExclusionCriteria(selectedExclusionCriteria));
  },
});

export default connect(mapStateToProps, mapDispatchToProps)(CriteriaAiAssistedView);
