import React, { useMemo, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { Button, Input, Collapse } from "antd";
import { Dropdown } from "../../../components";
import { useProject } from "../../../hooks";
import { RootState, setProjects, updateCohortSelectedProject } from "../../../store";
import { InsightsContainer } from "./InsightsContainer";

const optionsArr = [
  {
    id: 1,
    key: "NCTID(Extract from clinical Trials.gov)",
    value: "NCTID(Extract from clinical Trials.gov)",
  },
  {
    id: 2,
    key: "Protocol Document(Upload File)",
    value: "Protocol Document(Upload File)",
  },
  {
    id: 3,
    key: "Enter Text or Copy-Paste",
    value: "Enter Text or Copy-Paste",
  },
];

interface Props {
  displaySelectProjectDropdown?: boolean;
  isDrawerCollapsed: boolean;
  isAnalyzeCohortLoading: boolean;
  setIsDrawerCollapsed: React.Dispatch<React.SetStateAction<boolean>>;
  setIsAnalyzeCohortLoading: (loading: boolean) => void;
  projectId: number;
}

export const InsightsDrawer = (props: Props) => {
  const [isDropdownSelected, setIsDropdownSelected] = useState(true); //to enable or disable buttons
  const { displaySelectProjectDropdown, projectId } = props;
  const dispatch = useDispatch();
  const { getProjects, isProjectsLoading } = useProject();
  const { selectedProject } = useSelector((state: RootState) => state.cohort);
  const projects = useSelector((state: RootState) => state.project.list);

  const [selectedDataSourceMethod, setSelectedDataSourceMethod] = useState<string | undefined>(
    "Protocol Document(Upload File)"
  );
  const onSelectOption = (value: string, option: any) => {
    setSelectedDataSourceMethod(option.key);
    setIsDropdownSelected(option !== '');
    // console.log("SELECTE", option.key, "VALUE:", value);
  };
  
  const projectOptions = useMemo(() => {
    return optionsArr.map((item) => ({id: item.id, key: item.key, value: item.value }));
  }, [projects]);

  return (
    
    <div className={`cohort-drawer basic-detail-content flex flex-column gp no-wrap`}>
      {displaySelectProjectDropdown && (
        <div className="dropdown" style={{width:'35%'}}>
          <p className="cohort-drawer-title"> Select Data Source</p>
          <div className="ai-select-box">
            <Dropdown
              value={selectedDataSourceMethod}
              options={projectOptions}
              loading={isProjectsLoading}
              onSelect={onSelectOption}
              placeholder="Select"
            />
          </div>
          {/* You can handle selectedProjectError as needed */}
        </div>
      )}
      <InsightsContainer
        isDrawerCollapsed={true}
        setDataSourceMethod={selectedDataSourceMethod} // Pass selected method to InsightsContainer
        setIsDrawerCollapsed={props.setIsDrawerCollapsed} // Pass setIsDrawerCollapsed prop
        isCohortAsideCollapsed={false} // Adjust as needed
        isDropdownSelected={isDropdownSelected}
        setIsCohortAsideCollapsed={(value: React.SetStateAction<boolean>): void => {
          throw new Error("Function not implemented.");
        }}
        projectId={projectId}
      />
    </div>
  );
};
