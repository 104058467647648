import { Button } from "antd";
import React, { useCallback, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useFetchCohortPatients } from "../../../hooks";
import {
  RootState,
  updateSelectedAnalyzeExclusionCriteria,
  updateSelectedAnalyzeInclusionCriteria,
} from "../../../store";
import {
  CRITERIAS,
  INCLUSIONS_EXCLUSIONS_EXPORT_OPTIONS,
  NEGATION,
  capitalizeFirstLetter,
} from "../../../utils";
import { AnalyzeCriteria } from "./AnalyzeCriteria";
import { getCriteriaEntry } from "./criteriaUtil";
import {
  downloadDataAsXlsx,
  downloadFile,
  exportInclusionsExclusionsToCSV,
} from "../../../components/cohort/cohortExportUtil";
import { Dropdown } from "../../../components";

const filterInclusionsExclusions = (
  inclusions: IAnalyzeSelectedInclusionExclusionCriteria[],
  exclusions: IAnalyzeSelectedInclusionExclusionCriteria[]
) => {
  const filteredInclusions = inclusions
    .filter((inclusion) => inclusion.concepts.length > 0)
    .map((inclusion) => ({
      id: inclusion.id,
      criteria_text: inclusion.text,
      entities: inclusion.concepts.map((concept) => ({
        entity_text: concept.criterion_text,
        entity_type: concept.data_entity.toLowerCase().includes(NEGATION)
          ? capitalizeFirstLetter(NEGATION)
          : capitalizeFirstLetter(concept.data_entity),
        snomed_code: concept.snomed_code,
        hspcs_code: concept.hspcs_code,
        loinc_code: concept.loinc_code,
        icd_code: concept.icd_code,
        icd_confidence_score: concept.icd_confidence_score,
        icd_description: concept.icd_description,
        rxnorm_code: concept.rxnorm_code,
        rxnorm_confidence_score: concept.rxnorm_confidence_score,
        rxnorm_description: concept.rxnorm_description,
        negation: concept.data_entity.toLowerCase().includes(NEGATION) ? "Yes" : "",
      })),
    }));
  const filteredExclusions = exclusions
    .filter((exclusion) => exclusion.concepts.length > 0)
    .map((exclusion) => ({
      id: exclusion.id,
      criteria_text: exclusion.text,
      entities: exclusion.concepts.map((concept) => ({
        entity_text: concept.criterion_text,
        entity_type: concept.data_entity.toLowerCase().includes(NEGATION)
          ? capitalizeFirstLetter(NEGATION)
          : capitalizeFirstLetter(concept.data_entity),
        snomed_code: concept.snomed_code,
        hspcs_code: concept.hspcs_code,
        loinc_code: concept.loinc_code,
        icd_code: concept.icd_code,
        icd_confidence_score: concept.icd_confidence_score,
        icd_description: concept.icd_description,
        rxnorm_code: concept.rxnorm_code,
        rxnorm_confidence_score: concept.rxnorm_confidence_score,
        rxnorm_description: concept.rxnorm_description,
        negation: concept.data_entity.toLowerCase().includes(NEGATION) ? "Yes" : "",
      })),
    }));

  return {
    inclusions: filteredInclusions,
    exclusions: filteredExclusions,
  };
};

const getCriteria = (
  criteria: IAnalyzeSelectedInclusionExclusionCriteria[],
  selectedCriteria: number[]
) => {
  return criteria
    .map((item) => ({
      ...item,
      isSelected: selectedCriteria.includes(item.id),
    }))
    .filter((item) => item.isSelected)
    .map((item) => {
      const { content } = getCriteriaEntry({
        criteria: item.criterion,
        removeHighlight: true,
      });
      return content;
    })
    .join("\n");
};

interface Props {
  isAnalyzeCohortLoading: boolean;
  displaySelectProjectDropdown?: boolean;
  setIsAnalyzeCohortLoading: (loading: boolean) => void;
  cohortAsideRef: React.MutableRefObject<any>;
  setSelectedProjectError: React.Dispatch<React.SetStateAction<string>>;
  setIsDrawerCollapsed: React.Dispatch<React.SetStateAction<boolean>>;
}

export const AnalyseCriteriaPanel = ({
  isAnalyzeCohortLoading,
  displaySelectProjectDropdown,
  cohortAsideRef,
  setIsAnalyzeCohortLoading,
  setSelectedProjectError,
  setIsDrawerCollapsed,
}: Props) => {
  const dispatch = useDispatch();
  const { analyzeCohortCriteria, selectedProject, nctId } = useSelector(
    (state: RootState) => state.cohort
  );

  const { fetchPatients } = useFetchCohortPatients();
  // const [loading, setLoading] = useState(false);
  const [selectedInclusionCriteria, setSelectedInclusionCriteria] = useState([] as number[]);
  const [selectedExclusionCriteria, setSelectedExclusionCriteria] = useState([] as number[]);

  const isGenerateCohortDisabled =
    selectedInclusionCriteria.length === 0 && selectedExclusionCriteria.length === 0;

  const onGenerateCohortClick = useCallback(async () => {
    if (
      (displaySelectProjectDropdown && selectedProject.id !== -1) ||
      !displaySelectProjectDropdown
    ) {
      setIsAnalyzeCohortLoading(true);
      const inclusion = getCriteria(analyzeCohortCriteria.inclusion, selectedInclusionCriteria);
      const exclusion = getCriteria(analyzeCohortCriteria.exclusion, selectedExclusionCriteria);
      await fetchPatients(nctId, inclusion, exclusion);
      setIsDrawerCollapsed(true);
      dispatch(updateSelectedAnalyzeInclusionCriteria(selectedInclusionCriteria));
      dispatch(updateSelectedAnalyzeExclusionCriteria(selectedExclusionCriteria));
      setIsAnalyzeCohortLoading(false);
    } else {
      setSelectedProjectError("Please select a project to continue.");
      cohortAsideRef.current.scrollTo({ top: 0, behavior: "smooth" });
    }
  }, [
    analyzeCohortCriteria.exclusion,
    analyzeCohortCriteria.inclusion,
    dispatch,
    fetchPatients,
    nctId,
    selectedExclusionCriteria,
    selectedInclusionCriteria,
    setIsAnalyzeCohortLoading,
    cohortAsideRef,
    selectedProject.id,
    displaySelectProjectDropdown,
    setSelectedProjectError,
    setIsDrawerCollapsed,
  ]);

  const handleDropdownOptionSelect = (value: string) => {
    switch (value) {
      case INCLUSIONS_EXCLUSIONS_EXPORT_OPTIONS[0].value:
        downloadFile({
          data: JSON.stringify(
            filterInclusionsExclusions(
              analyzeCohortCriteria.inclusion,
              analyzeCohortCriteria.exclusion
            )
          ),
          fileName: `${nctId}.json`,
          fileType: "text/json",
        });
        break;
      case INCLUSIONS_EXCLUSIONS_EXPORT_OPTIONS[1].value:
        exportInclusionsExclusionsToCSV(
          analyzeCohortCriteria.inclusion,
          analyzeCohortCriteria.exclusion,
          "\t",
          `${nctId}.csv`
        );
        break;
      case INCLUSIONS_EXCLUSIONS_EXPORT_OPTIONS[2].value:
        exportInclusionsExclusionsToCSV(
          analyzeCohortCriteria.inclusion,
          analyzeCohortCriteria.exclusion,
          ",",
          `${nctId}.csv`
        );
        break;
      case INCLUSIONS_EXCLUSIONS_EXPORT_OPTIONS[3].value:
        downloadDataAsXlsx(
          analyzeCohortCriteria.inclusion,
          analyzeCohortCriteria.exclusion,
          "\t",
          `${nctId}.xlsx`
        );
    }
  };

  return (
    <div>
      <AnalyzeCriteria
        title={CRITERIAS.INCLUSION_CRITERIA}
        data={analyzeCohortCriteria.inclusion}
        updateSelectedCriteriaIds={setSelectedInclusionCriteria}
      />
      <AnalyzeCriteria
        title={CRITERIAS.EXCLUSION_CRITERIA}
        data={analyzeCohortCriteria.exclusion}
        updateSelectedCriteriaIds={setSelectedExclusionCriteria}
      />
      <div className="flex jce gp-10">
        <Dropdown
          className="w-auto ai-select dropdown-export"
          options={INCLUSIONS_EXCLUSIONS_EXPORT_OPTIONS}
          onSelect={(value, option) => handleDropdownOptionSelect(option.value)}
          loading={false}
          value="Download as"
          disabled={
            analyzeCohortCriteria.inclusion.length === 0 &&
            analyzeCohortCriteria.inclusion.length === 0
          }
        />
        <Button
          className="fill"
          disabled={isGenerateCohortDisabled}
          loading={isAnalyzeCohortLoading}
          onClick={() => onGenerateCohortClick()}
        >
          Generate Cohort
        </Button>
      </div>
    </div>
  );
};
