import { Dispatch } from "redux";
import { connect } from "react-redux";
import { closeModal, RootState } from "../../../store";
import { MODAL_TYPE } from "../../../utils";
import CriteriaCategories from "./CriteriaCategories";

type StateProps = {
    visibility: boolean;
};
type DispatchProps = {
  handleClose: () => void;
};

export type CriteriaCategoriesProps = StateProps & DispatchProps;

const mapStateToProps = (state: RootState): StateProps => ({
  visibility: state.ui.modal[MODAL_TYPE.CRITERIA_CATEGORIES],
});

const mapDispatchToProps = (dispatch: Dispatch): DispatchProps => ({
  handleClose: () => {
    dispatch(closeModal(MODAL_TYPE.CRITERIA_CATEGORIES));
  },
});

export default connect(mapStateToProps, mapDispatchToProps)(CriteriaCategories);
  