import React, { useEffect, useState } from "react";
import TasksApi from "../../../../api/tasks.api";
import { Loading } from "../../../../components";
import { openNotificationWithIcon } from "../../../../utils";
import { CopyToClipboard } from "react-copy-to-clipboard";
import { Button } from "antd";
import { DownloadOutlined } from "@ant-design/icons";
import { ClipboardIcon } from "../../../../assets/icons";

interface Props {
  query: string;
  isLoading: boolean;
  setIsLoading: React.Dispatch<React.SetStateAction<boolean>>;
}

export const GenerateSummary = ({ query, isLoading, setIsLoading }: Props) => {
  const [queryResponse, setQueryResponse] = useState("");
  useEffect(() => {
    const getTask = async () => {
      try {
        if (query) {
          const { data } = await new TasksApi().protocolSummarizer(query);
          setQueryResponse(data["results"]);
        }
      } catch (e: any) {
        openNotificationWithIcon("", e.response.data.message, "error");
        setQueryResponse("");
      } finally {
        setIsLoading(false);
      }
    };
    getTask();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [query]); //setIsLoading]);

  function convertToCSV(protocol: string) {
    let csv = "Protocol Summary\n";
    const summary = protocol || "";
    csv += `"${summary.replace(/"/g, '""')}"\n`;
    return csv;
  }

  function downloadAsCsv() {
    const csvContent = convertToCSV(queryResponse[0]);
    const blob = new Blob([csvContent], { type: "text/csv;charset=utf-8;" });
    const url = URL.createObjectURL(blob);
    const link = document.createElement("a");
    link.setAttribute("href", url);
    link.setAttribute("download", "protocol_summary.csv");
    document.body.appendChild(link);
    link.click();
    document.body.removeChild(link);
  }

  return (
    <>
      <div className="flex jcsb">
        <h1 className="m-b">Result</h1>
        {!isLoading && queryResponse && (
          <div>
            <DownloadOutlined
              onClick={downloadAsCsv}
              title="Export to CSV"
              style={{ fontSize: "21px", marginRight: "1em" }}
            />
            <CopyToClipboard
              text={queryResponse}
              onCopy={() => openNotificationWithIcon("", "Text Copied", "success")}
            >
              <ClipboardIcon className="copyIcon" />
            </CopyToClipboard>
          </div>
        )}
      </div>
      <br />
      {isLoading ? (
        <Loading />
      ) : queryResponse ? (
        <>
          <div className="query-response">{queryResponse}</div>
          <div className="tr m-t"></div>
          {/* <CopyToClipboard text={queryResponse} onCopy={() => openNotificationWithIcon("", "Text Copied", "success")}>
          <Button className="outline">Copy to Clipboard</Button>
        </CopyToClipboard> */}
        </>
      ) : (
        <Loading
          style={{
            width: "-webkit-fill-available",
          }}
        />
      )}
    </>
  );
};
