import { useCallback, useState } from "react";
import { useDispatch } from "react-redux";
import { cohortService } from "../api";
import {
  updateAnalyzeExclusionCriteria,
  updateAnalyzeInclusionCriteria,
  updateExclusionCriteria,
  updateInclusionCriteria,
} from "../store";
import { openNotificationWithIcon } from "../utils";

export const useFetchCohortCriteria = () => {
  const dispatch = useDispatch();
  const [isCohortCriteriaLoading, setIsCohortCriteriaLoading] = useState(false);
  const [isAnalyzeCohortLoading, setIsAnalyzeCohortLoading] = useState(false);
  const [fetchCohortCriteriaError, setFetchCohortCriteriaError] = useState("");

  const fetchCohortCriteriaFromNctId = useCallback(
    async (nctId: string) => {
      try {
        setIsCohortCriteriaLoading(true);
        setFetchCohortCriteriaError("");
        dispatch(updateInclusionCriteria(""));
        dispatch(updateExclusionCriteria(""));
        dispatch(updateAnalyzeInclusionCriteria([]));
        dispatch(updateAnalyzeExclusionCriteria([]));
        const { data } = await cohortService.getInclusionExclusionFromNctid(nctId);
        dispatch(updateInclusionCriteria(data.inc));
        dispatch(updateExclusionCriteria(data.exc));
      } catch (err: any) {
        const res =
          err.response.data?.message || "Something went wrong, please check NCTID and try again";
        setFetchCohortCriteriaError(res);
      } finally {
        setIsCohortCriteriaLoading(false);
      }
    },
    [dispatch]
  );

  const analyzeCohortCriteria = useCallback(
    async (nctId: string, inclusion: string, exclusion: string) => {
      try {
        setIsAnalyzeCohortLoading(true);
        const { data } = await cohortService.analyzeCohortCriteria(inclusion, exclusion, nctId);
        return data;
      } catch (err: any) {
        const res = err.response.data?.message || "Something went wrong, could not load data";
        openNotificationWithIcon("", res, "error");
      } finally {
        setIsAnalyzeCohortLoading(false);
      }
    },
    []
  );

  return {
    isCohortCriteriaLoading,
    fetchCohortCriteriaError,
    setFetchCohortCriteriaError,
    fetchCohortCriteriaFromNctId,
    analyzeCohortCriteria,
    isAnalyzeCohortLoading,
  };
};
