import React, { useMemo } from "react";
import { useLocation, useNavigate } from "react-router-dom";
import { useDispatch } from "react-redux";
import { Header } from "antd/lib/layout/layout";
import { logoutUser } from "../../../store";
import { PAGE_TITLE } from "../../../utils";
import { PAGE_URL } from "../../../utils";
import { LogoutIcon, MenuIcon } from "../../../assets/icons";
import { useUser } from "../../../hooks";
import { BackArrow } from "../../../components";
import "./app-header.scss";

export const AppHeader = () => {
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const location = useLocation();
  const { firstName, lastName, roleName } = useUser();
  const { pageTitle, displayBackButton } = useMemo(() => {
    const pathname = location.pathname;
    let title = PAGE_TITLE[0].title;
    let backButton = false;
    PAGE_TITLE.forEach((page) => {
      if (pathname.includes(page.route)) {
        title = page.title;
        backButton = page.displayBackButton;
      }
    });
    return { pageTitle: title, displayBackButton: backButton };
  }, [location.pathname]);

  const logout = (e: any) => {
    e.preventDefault();
    dispatch(logoutUser());
    navigate(PAGE_URL.LOGIN);
  };

  return (
    <Header className="flex aic jcsb header">
      <div className="flex gp aic">
        {displayBackButton && <BackArrow />}
        <h1 className="header-h1 m-0">{pageTitle}</h1>
      </div>
      {/* <Button onClick={() => toggleTheme()}>toggle theme</Button> */}
      <div className="header-right flex gp-10 aic">
        <div className="header-content-menu pr cursor-pointer">
          <a className="header-dropdown" href="#">
            <MenuIcon />
          </a>
          <div className="header-dropdown-menu flex flex-column">
            <div className="header-content-profile-content">
              <p>
                {firstName} {lastName} <span>{roleName}</span>
              </p>
            </div>
            {/* <a
              href="#"
              className="header-dropdown-menu-item flex aic no-wrap"
              onClick={(e) => {
                e.preventDefault();
                dispatch(openModal(MODAL_TYPE.EDIT_PROFILE));
              }}
            >
              <EditProfileIcon />
              Edit Profile
            </a> */}
            <a href="#" className="header-dropdown-menu-item flex aic no-wrap" onClick={logout}>
              <LogoutIcon />
              Logout
            </a>
          </div>
        </div>
      </div>
    </Header>
  );
};
