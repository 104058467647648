import React, { useCallback, useState } from "react";
import { CloseOutlined } from "@ant-design/icons";
import { Button, Modal } from "antd";
import { CriteriaAiAssistedViewProps } from ".";
import { AnalyzeCriteriaInstructions, AnalyzeCriteria } from "../../cohort/cohort-container";
import { getCriteriaEntry } from "../../cohort/cohort-container/criteriaUtil";
import { useFetchCohortPatients } from "../../../hooks";
import { CRITERIAS } from "../../../utils";

const getCriteria = (
  criteria: IAnalyzeSelectedInclusionExclusionCriteria[],
  selectedCriteria: number[]
) => {
  return criteria
    .map((item) => ({
      ...item,
      isSelected: selectedCriteria.includes(item.id),
    }))
    .filter((item) => item.isSelected)
    .map((item) => {
      const { content } = getCriteriaEntry({
        criteria: item.criterion,
        removeHighlight: true,
      });
      return content;
    })
    .join("\n");
};

const CriteriaAiAssistedView = (props: CriteriaAiAssistedViewProps) => {
  const {
    visibility,
    nctId,
    analyzeExclusionCriteria,
    analyzeInclusionCriteria,
    handleClose,
    updateSelectedAnalyzeExclusionCriteria,
    updateSelectedAnalyzeInclusionCriteria,
  } = props;
  const { fetchPatients } = useFetchCohortPatients();
  const [loading, setLoading] = useState(false);
  const [selectedInclusionCriteria, setSelectedInclusionCriteria] = useState([] as number[]);
  const [selectedExclusionCriteria, setSelectedExclusionCriteria] = useState([] as number[]);

  const isGenerateCohortDisabled =
    selectedInclusionCriteria.length === 0 && selectedExclusionCriteria.length === 0;

  const onGenerateCohortClick = useCallback(async () => {
    setLoading(true);
    const inclusion = getCriteria(analyzeInclusionCriteria, selectedInclusionCriteria);
    const exclusion = getCriteria(analyzeExclusionCriteria, selectedExclusionCriteria);
    await fetchPatients(nctId, inclusion, exclusion);
    updateSelectedAnalyzeInclusionCriteria(selectedInclusionCriteria);
    updateSelectedAnalyzeExclusionCriteria(selectedExclusionCriteria);
    setLoading(false);
    handleClose();
  }, [
    analyzeExclusionCriteria,
    analyzeInclusionCriteria,
    fetchPatients,
    handleClose,
    nctId,
    selectedExclusionCriteria,
    selectedInclusionCriteria,
    updateSelectedAnalyzeExclusionCriteria,
    updateSelectedAnalyzeInclusionCriteria,
  ]);

  return (
    <Modal
      className="autonomizeModal addClient"
      centered
      visible={visibility}
      closeIcon={<CloseOutlined onClick={handleClose} />}
      footer={null}
    >
      <div className="addClient-title">
        <h1>AI Assisted View</h1>
      </div>
      <div className="addClient-content">
        <AnalyzeCriteriaInstructions />
        <AnalyzeCriteria
          title={CRITERIAS.INCLUSION_CRITERIA}
          data={analyzeInclusionCriteria}
          updateSelectedCriteriaIds={setSelectedInclusionCriteria}
        />
        <AnalyzeCriteria
          title={CRITERIAS.EXCLUSION_CRITERIA}
          data={analyzeExclusionCriteria}
          updateSelectedCriteriaIds={setSelectedExclusionCriteria}
        />
        <div className="tr m-t">
          <Button
            className="fill"
            disabled={isGenerateCohortDisabled}
            loading={loading}
            onClick={() => onGenerateCohortClick()}
          >
            Generate Cohort
          </Button>
        </div>
      </div>
    </Modal>
  );
};

export default CriteriaAiAssistedView;
