import React, { useCallback, useEffect, useState } from "react";
import { DeleteIcon } from "../../../assets/icons";
import {
  DOCUMENTS_LIST_TABLE_STATUS,
  MODAL_TYPE,
  getLocalDateTimeStringFromISO,
  openNotificationWithIcon,
} from "../../../utils";
import { Button, Table, TableColumnsType } from "antd";
import { useDispatch, useSelector } from "react-redux";
import { RootState, openModal, updateDeleteConfirmation, updateDocumentName } from "../../../store";
import { AppPagination } from "../../../components";
import { usePagination } from "../../../hooks";
import { IGetDocumentsRequest, cohortService } from "../../../api";
import { ReloadOutlined } from "@ant-design/icons";
import "./cohort.scss";
interface DocumentListType {
  key: React.Key;
  name: string;
  createdAt: string;
  status: string;
  id: number;
}

interface Props {
  projectId: number;
  onGenerateQuestionnaire: () => void;
  ieSimplificationInput: string;
  setDocumentId: React.Dispatch<React.SetStateAction<number>>;
}

export const DocumentsTable = (props: Props) => {
  const [deleteDocument, setDeleteDocument] = useState(-1);
  const { projectId, onGenerateQuestionnaire, ieSimplificationInput, setDocumentId } = props;
  const dispatch = useDispatch();
  const { isDeleteConfirmed } = useSelector((state: RootState) => state.ui);
  const { onPaginationChange, page, pageSize, pageSizeOptions } = usePagination();
  const [documentsList, setDocumentsList] = useState([]);
  const [totalcount, setTotalCount] = useState(0);
  const [loading, setLoading] = useState(false);

  const getOffsetFromPageAndPageSize = () => (page - 1) * pageSize;

  const getDocumentsFilterObject = useCallback(() => {
    const obj: IGetDocumentsRequest = {
      limit: pageSize,
      offset: getOffsetFromPageAndPageSize(),
    };
    return { ...obj };
  }, [page, pageSize]);

  const fetchDocuments = async () => {
    try {
      setLoading(true);
      const res = await cohortService.getDocuments(projectId, getDocumentsFilterObject());
      setTotalCount(res.data.data.totalCount);
      setDocumentsList(res.data.data.documents);
    } catch (err: any) {
      openNotificationWithIcon("", err.response.data.message, "error");
    } finally {
      setLoading(false);
    }
  };

  useEffect(() => {
    const handleDeleteDocuments = async () => {
      await cohortService.deleteDocuments(deleteDocument);
    };
    if (deleteDocument !== -1 && isDeleteConfirmed) {
      handleDeleteDocuments();
      dispatch(updateDeleteConfirmation(false));
      fetchDocuments();
    }
  }, [deleteDocument, dispatch, isDeleteConfirmed, projectId]);

  useEffect(() => {
    fetchDocuments();
  }, [page, pageSize, ieSimplificationInput]);

  const getClass = (value: string) => {
    switch (value) {
      case DOCUMENTS_LIST_TABLE_STATUS.PROCESSED:
        return "#44c658";
      case DOCUMENTS_LIST_TABLE_STATUS.ERROR:
        return "#f44336";
      case DOCUMENTS_LIST_TABLE_STATUS.PROCESSING:
        return "#ff9800";
      default:
        return "#475569";
    }
  };

  const getColumns = (): TableColumnsType<DocumentListType> => [
    {
      title: "Case ID",
      dataIndex: "name",
      align: "left",
      width: 10,
      className: "table-name",
    },
    {
      title: "Uploaded Date",
      dataIndex: "createdAt",
      align: "center",
      className: "table-createdAt",
      render: (text, value) => <div>{getLocalDateTimeStringFromISO(text)}</div>,
    },
    {
      title: "Processing Status",
      dataIndex: "status",
      align: "center",
      render: (text, value) => (
        <div>
          <div className="status" style={{ color: getClass(value.status) }}>
            <p style={{ color: getClass(value.status) }}>{value.status}</p>
          </div>
        </div>
      ),
      className: "table-status",
    },
    {
      title: "Action",
      dataIndex: "action",
      key: "action",
      align: "right",
      render: (text, value) => (
        <a className="p-10">
          <DeleteIcon
            width={18}
            height={18}
            onClick={() => {
              setDeleteDocument(value.id);
              dispatch(openModal(MODAL_TYPE.DELETE_CONFIRMATION));
            }}
          />
        </a>
      ),
      className: "table-action",
    },
  ];

  return (
    <div className="table-container">
      <div className="button-contianer">
        <Button className="fill" icon={<ReloadOutlined />} onClick={fetchDocuments}>
          Refresh
        </Button>
      </div>
      <Table
        columns={getColumns()}
        dataSource={documentsList}
        pagination={false}
        onRow={(record) => ({
          onClick: () => {
            if (record.status === "PROCESSED") {
              dispatch(updateDocumentName(record.name));
              setDocumentId(record.id);
              onGenerateQuestionnaire();
            } else if (record.status === "PROCESSING") {
              openNotificationWithIcon("", "File is still being processed", "info");
            } else {
              openNotificationWithIcon("", "File processing failed", "error");
            }
          },
        })}
        loading={loading}
        className="table"
      />
      <AppPagination
        className="tr m-t"
        {...{
          showSizeChanger: true,
          current: page,
          total: totalcount,
          pageSize,
          pageSizeOptions,
          onChange: onPaginationChange,
        }}
      />
    </div>
  );
};
