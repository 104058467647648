import axios, { AxiosRequestConfig } from "axios";
import { useNavigate } from "react-router-dom";
import { store } from "../App";
import { closeAllModal, logoutUser } from "../store";
import { PAGE_URL } from "../utils";

export const authHeaders = (headers: any, token: string): any => {
  const namespace = "x-access-token";
  headers[namespace] = token;
  return headers;
};

export const withAuthHeaders = (headers: any): any => {
  const token = store.getState().user.user.accessToken;
  return authHeaders(headers, token || "");
};

const apiClientDefaultOptions = {
  baseURL: window._env_.REACT_APP_API_BASE_URL,
  headers: { "Content-Type": "application/json" },
};
const apiClient = axios.create(apiClientDefaultOptions);

const c2qDefaultOptions = {
  baseURL: window._env_.REACT_APP_C2Q_API_BASE_URL,
  headers: { "Content-Type": "application/json" },
};
const c2qApiClient = axios.create(c2qDefaultOptions);

apiClient.interceptors.request.use(
  (config: AxiosRequestConfig) => {
    //   set user token here
    config.headers = withAuthHeaders({});
    return config;
  },
  (error: any) => {
    Promise.reject(error);
  }
);

apiClient.interceptors.response.use(
  (res) => res,
  (error: any) => {
    // handle user session refresh here
    const pathname = window.location.pathname;
    if (
      (pathname !== PAGE_URL.LOGIN && error.response.status === 401) ||
      error.response.status === 403
    ) {
      store.dispatch(closeAllModal());
      store.dispatch(logoutUser());
      useNavigate()(PAGE_URL.LOGIN);
    }
    if (error.response.status >= 500) {
      error.response.message = "Something went wrong!";
    }
    return Promise.reject(error);
  }
);

export { apiClient, c2qApiClient };
