import { isEmptyObject } from "../../../utils";
import { getCriteriaEntry, ICriteriaCategory } from "../../cohort/cohort-container/criteriaUtil";

export const filterDuplicateElements = (
  patientDetail: (string | undefined)[],
  delimiter = " | "
): string => {
  if (!patientDetail) {
    return "";
  }
  return Array.from(new Set(patientDetail)).join(delimiter);
};

export const getInclusionHighlightTags = (
  criteria: IAnalyzeSelectedInclusionExclusionCriteria[]
) => {
  const highlightTags = [] as string[];
  const selectedCriteria = criteria.filter((item) => item.isSelected).map((item) => item.criterion);
  selectedCriteria.forEach((inc) => {
    const { highlights } = getCriteriaEntry({ criteria: inc, removeHighlight: true });
    highlightTags.push(...highlights.map((item) => item.mappedConceptText));
  });
  return highlightTags
    .filter((item) => item !== "")
    .map((item) => item.substring(1, item.length - 1));
};

export const getExclusionHighlightTags = (
  criteria: IAnalyzeSelectedInclusionExclusionCriteria[]
) => {
  const highlightTags = [] as ICriteriaCategory[];
  const selectedCriteria = criteria.filter((item) => item.isSelected).map((item) => item.criterion);
  selectedCriteria.forEach((inc) => {
    const { highlights } = getCriteriaEntry({ criteria: inc, removeHighlight: true });
    highlightTags.push(...highlights.filter((item) => item.mappedConceptText));
  });
  return highlightTags.map((item) => ({
    ...item,
    mappedConceptText: item.mappedConceptText.substring(1, item.mappedConceptText.length - 1),
  }));
};

export const getMatchedTagsFromPatientDetails = (tags: string[], patientDetails: IPatient) => {
  let res = {} as IPatient;
  if (!isEmptyObject(patientDetails)) {
    const conditions = patientDetails.conditionOccurrences.filter(
      (item) => item.conditionConcept && tags.includes(item.conditionConcept)
    );
    const procedure = patientDetails.procedureOccurrences.filter(
      (item) => item.procedureConcept && tags.includes(item.procedureConcept)
    );
    const measurements = patientDetails.measurements.filter(
      (item) => item.measurementConcept && tags.includes(item.measurementConcept)
    );
    const drugs = patientDetails.drugExposures.filter(
      (item) => item.drugConcept && tags.includes(item.drugConcept)
    );
    const observation = patientDetails.observation.filter(
      (item) => item.observationConcept && tags.includes(item.observationConcept)
    );
    res = {
      conditionOccurrences: conditions,
      drugExposures: drugs,
      measurements,
      observation,
      procedureOccurrences: procedure,
    };
  }
  return res;
};
