import React, { useEffect, useState } from "react";
import {
  ProjectDetailEligibilityCriteria,
  ProjectDetailOverview,
  ProjectDetailSavedCohort,
  ProjectDetailTabsMapper,
  ProjectPatientsList,
  ProtocolInsights
} from "./project-detail-container";
import { PROJECT_DETAIL_TABS } from ".";
import "./project-detail-container.scss";

interface Props {
  projectDetail: IProject;
}

export const ProjectDetailContainer = ({ projectDetail }: Props) => {
  const { id, name, description } = projectDetail;
  const [component, setComponent] = useState(<></>);
  const [selectedTab, setSelectedTab] = useState(PROJECT_DETAIL_TABS.OVERVIEW.name);

  const onTabChange = (key: string) => {
    setSelectedTab(key);
  };

  useEffect(() => {
    let res = <></>;
    switch (selectedTab) {
      case PROJECT_DETAIL_TABS.OVERVIEW.name:
        res = <ProjectDetailOverview description={description} />;
        break;
      case PROJECT_DETAIL_TABS.SITE_FEASIBILITY.name:
        res = <ProjectDetailEligibilityCriteria projectId={id} projectName={name} />;
        break;
      // case PROJECT_DETAIL_TABS.PATIENTS_LIST.name:
      //   res = <ProjectPatientsList />;
      //   break;
      case PROJECT_DETAIL_TABS.PROTOCOL_INSIGHTS.name:
        res = <ProtocolInsights projectId={projectDetail.id} projectName={projectDetail.name}  />
        console.log("Project ID:",projectDetail.id,"Project Name:",projectDetail.name)
        // res = <ProtocolInsights isDrawerCollapsed={false} isAnalyzeCohortLoading={false} isCohortAsideCollapsed={false} />
        break;
      case PROJECT_DETAIL_TABS.SAVED_COHORTS.name:
        res = <ProjectDetailSavedCohort projectId={id} setSelectedTab={setSelectedTab} />;
        break;
    }
    setComponent(res);
  }, [description, id, name, projectDetail, selectedTab]);

  return (
    <>
      <ProjectDetailTabsMapper {...{ onTabChange, projectName: name, selectedTab }} />
      <div className="tabs-content">{component}</div>
    </>
  );
};
