import React from "react";
import { Tabs } from "antd";
import { PROJECT_DETAIL_TABS } from "..";

const { TabPane } = Tabs;

interface Props {
  selectedTab: string;
  onTabChange: (key: string) => void;
  projectName: string;
}

export const ProjectDetailTabsMapper = (props: Props) => {
  const { onTabChange, projectName, selectedTab } = props;

  return (
    <>
      <div className="project-heading">
        <h2 className="project-title-heading">Project</h2>
        {projectName && <h2 className="project-title-name">{projectName}</h2>}
      </div>
      <div className="tabs">
        <Tabs onChange={onTabChange} activeKey={selectedTab}>
          {Object.values(PROJECT_DETAIL_TABS).map((tab) => (
            <TabPane tab={tab.name} key={tab.name} />
          ))}
        </Tabs>
      </div>
    </>
  );
};
