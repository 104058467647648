import { connect } from "react-redux";
import { Dispatch } from "redux";
import { closeModal, RootState } from "../../../store";
import { MODAL_TYPE } from "../../../utils";
import PatientDetails from "./PatientDetails";

type StateProps = {
  visibility: boolean;
  patientId: string;
  inclusion: IAnalyzeSelectedInclusionExclusionCriteria[];
  exclusion: IAnalyzeSelectedInclusionExclusionCriteria[];
};
type DispatchProps = {
  handleClose: () => void;
};

export type PatientDetailsProps = StateProps & DispatchProps;

const mapStateToProps = (state: RootState): StateProps => ({
  visibility: state.ui.modal[MODAL_TYPE.PATIENT_DETAILS],
  patientId: state.cohort.cohortPatientId,
  inclusion: state.cohort.analyzeCohortCriteria.inclusion,
  exclusion: state.cohort.analyzeCohortCriteria.exclusion,
});

const mapDispatchToProps = (dispatch: Dispatch): DispatchProps => ({
  handleClose: () => {
    dispatch(closeModal(MODAL_TYPE.PATIENT_DETAILS));
  },
});

export default connect(mapStateToProps, mapDispatchToProps)(PatientDetails);
