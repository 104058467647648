import { createSlice, PayloadAction } from "@reduxjs/toolkit";

export const userInitialState: IUserSlice = {
  user: { role: {} } as IUser,
};

export const userSlice = createSlice({
  name: "user",
  initialState: userInitialState,
  reducers: {
    loginUser: (state: IUserSlice, { payload }: PayloadAction<IUser>) => {
      state.user = { ...payload, role: { ...payload.role } };
    },
    logoutUser: (state: IUserSlice) => {
      state.user = {} as IUser;
    },
    // updateCurrentUser: (state: UserSlice, { payload }: PayloadAction<IEditUserResponseData>) => {
    //   state.user = { ...state.user, ...payload };
    // },
  },
});

export const { loginUser, logoutUser } = userSlice.actions;

export default userSlice.reducer;
