import React, { useState } from "react";
import { Button, Input } from "antd";
import { ListDataIcon, TwoByTwoTileOption } from "../../assets/icons";
import { openModal } from "../../store";
import { MODAL_TYPE } from "../../utils";
import { useDispatch } from "react-redux";
import { ProjectsListInTable, ProjectsListInTiles } from "./projects-list-container";

const { Search } = Input;

enum VIEW_STYLE {
  TILES,
  LIST,
}

export const ProjectsListContainer = () => {
  const dispatch = useDispatch();
  const [viewStyle, setViewStyle] = useState(VIEW_STYLE.TILES);

  const onCreateProjectClick = () => {
    dispatch(openModal(MODAL_TYPE.ADD_EDIT_PROJECT));
  };

  return (
    <div>
      {/* <div className="flex jcsb aic "> */}
      {/* <Search
          className="record-search"
          placeholder="Search projects"
          style={{ width: "307px" }}
        /> */}
      <div className="flex gp aic">
        <TwoByTwoTileOption
          className={`view-control cursor-pointer ${
            viewStyle === VIEW_STYLE.TILES ? "active" : ""
          } `}
          onClick={() => setViewStyle(VIEW_STYLE.TILES)}
        />
        <ListDataIcon
          className={`view-control cursor-pointer ${
            viewStyle === VIEW_STYLE.LIST ? "active" : ""
          } `}
          onClick={() => setViewStyle(VIEW_STYLE.LIST)}
        />
        <Button className="outline" onClick={onCreateProjectClick}>
          Create Project
        </Button>
      </div>
      {/* </div> */}
      <div>
        {viewStyle === VIEW_STYLE.TILES ? <ProjectsListInTiles /> : <ProjectsListInTable />}
      </div>
    </div>
  );
};
