import { apiClient } from ".";

interface IUserLoginRequest {
  email: string;
  password: string;
}

interface IUserLoginResponse {
  data: IUser;
  status: string;
}

interface IUserForgotPasswordResponse {
  message: string;
  password: string;
}

const login = async (requestObject: IUserLoginRequest) => {
  const res = await apiClient.post<IUserLoginResponse>(`/auth/login`, requestObject);
  return res;
};

const forgotPassword = async (reqObj: { email: string }) => {
  const res = await apiClient.post<IUserForgotPasswordResponse>("/auth/forgot-password", reqObj);
  return res;
};

const resetPassword = async (reqObj: { password: string }, token: string) => {
  const res = await apiClient.post<IUserForgotPasswordResponse>(
    `/auth/reset-password?token=${token}`,
    reqObj
  );
  return res;
};

const createPassword = async (reqObj: { password: string }, token: string) => {
  const res = await apiClient.post<IUserForgotPasswordResponse>(
    `/auth/create-password?token=${token}`,
    reqObj
  );
  return res;
};

export const userService = {
  login,
  forgotPassword,
  resetPassword,
  createPassword
};
