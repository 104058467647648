import { useCallback, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { cohortService } from "../api";
import { getCriteriaEntry } from "../containers/cohort/cohort-container/criteriaUtil";
import {
  resetPatients,
  resetPostgreSQL,
  RootState,
  updateAnalyzeExclusionCriteria,
  updateAnalyzeInclusionCriteria,
  updatePatients,
  updatePatientsError,
  updatePostgreSQL,
  updateRequestId,
} from "../store";

export const useFetchCohortPatients = () => {
  const dispatch = useDispatch();
  const [loading, setLoading] = useState(false);
  const [getAllPatientsLoading, setAllPatientsLoading] = useState(false);
  const [patients, setPatients] = useState([] as InclusionExclusionCriteriaPatientRecord[]);
  const [error, setError] = useState("");
  const { requestId, analyzed } = useSelector((state: RootState) => state.cohort);

  const fetchAllPatients = useCallback(async () => {
    setAllPatientsLoading(true);
    const { data } = await cohortService.getAllPatients();
    setPatients(data.data);
    setAllPatientsLoading(false);
  }, []);

  const fetchCohortPatients = useCallback(
    async (nctId: string, inclusion: string[], exclusion: string[]) => {
      try {
        setError("");
        setLoading(true);
        const inclusionCriteria = inclusion
          .map((inc) => {
            const { content } = getCriteriaEntry({
              criteria: inc,
              removeHighlight: true,
            });
            return content;
          })
          .join("\n");
        const exclusionCriteria = exclusion
          .map((inc) => {
            const { content } = getCriteriaEntry({
              criteria: inc,
              removeHighlight: true,
            });
            return content;
          })
          .join("\n");
        const { data } = await cohortService.analyzeCohortCriteria(
          inclusionCriteria,
          exclusionCriteria,
          nctId
        );
        setPatients(data.queryResult);
        if (data.queryResult.length === 0)
          setError("No Data Found for Selected Inclusion/Exclusion Criteria");
      } catch (err: any) {
        const res = err.response.data?.message || "Something went wrong, could not load data";
        setError(res);
      } finally {
        setLoading(false);
      }
    },
    []
  );

  const fetchPatients = useCallback(
    async (nctId: string, inclusion: string, exclusion: string, setAnalyzeCohorts = false) => {
      try {
        dispatch(updatePatientsError(""));
        if (setAnalyzeCohorts) {
          const { data } = await cohortService.analyzeCriteria(inclusion, exclusion);
          dispatch(
            updateAnalyzeExclusionCriteria(
              data.display_exclude.map((item) => ({ ...item, isSelected: item.ehrstatus }))
            )
          );
          dispatch(
            updateAnalyzeInclusionCriteria(
              data.display_include.map((item) => ({ ...item, isSelected: item.ehrstatus }))
            )
          );
          dispatch(resetPatients());
          dispatch(resetPostgreSQL());
          dispatch(updateRequestId(data.requestId));
        } else {
          if (analyzed) {
            //  fetch patients
            const { data: patientsData } = await cohortService.getPatientsByRequestId(requestId);
            // fetch sql query
            const { data: sqlQueryData } = await cohortService.getSqlQuery2(requestId);
            dispatch(updatePatients(patientsData.queryResult));
            dispatch(updatePostgreSQL(sqlQueryData.sql));
            if (patientsData.queryResult.length === 0)
              dispatch(
                updatePatientsError("No Data Found for Selected Inclusion/Exclusion Criteria")
              );
          } else {
            // analyze criteria
            const { data } = await cohortService.analyzeCriteria(inclusion, exclusion);
            //  fetch patients
            const { data: patientsData } = await cohortService.getPatientsByRequestId(
              data.requestId
            );
            // fetch sql query
            const { data: sqlQueryData } = await cohortService.getSqlQuery2(data.requestId);
            dispatch(updatePatients(patientsData.queryResult));
            dispatch(updatePostgreSQL(sqlQueryData.sql));
            if (patientsData.queryResult.length === 0)
              dispatch(
                updatePatientsError("No Data Found for Selected Inclusion/Exclusion Criteria")
              );
            if (setAnalyzeCohorts) {
              dispatch(
                updateAnalyzeExclusionCriteria(
                  data.display_exclude.map((item) => ({ ...item, isSelected: item.ehrstatus }))
                )
              );
              dispatch(
                updateAnalyzeInclusionCriteria(
                  data.display_include.map((item) => ({ ...item, isSelected: item.ehrstatus }))
                )
              );
              dispatch(resetPatients());
              dispatch(resetPostgreSQL());
            }
          }
        }
      } catch (err: any) {
        const res = err.response.data?.message || "Something went wrong, could not load data";
        dispatch(updatePatientsError(res));
      }
    },
    [dispatch, analyzed, requestId]
  );

  const fetchSqlQuery = useCallback(
    async (nctId: string, inclusion: string[], exclusion: string[]) => {
      try {
        const { data } = await cohortService.getSqlQuery(inclusion, exclusion, nctId);
      } catch (err: any) {
        const res = err.response.data?.message || "Something went wrong, could not load data";
      }
    },
    []
  );

  return {
    loading,
    error,
    patients,
    fetchCohortPatients,
    fetchPatients,
    getAllPatientsLoading,
    fetchAllPatients,
    fetchSqlQuery,
  };
};
