import React from "react";
import CopyToClipboard from "react-copy-to-clipboard";
import { openNotificationWithIcon } from "../../../../utils";
import { Button } from "antd";
import { ClipboardIcon } from "../../../../assets/icons";
import { DownloadOutlined } from "@ant-design/icons";
import { Criteria, parseCriteriaText } from "./helper";

interface Props {
  cohortCriteria: {
    inclusion: string;
    exclusion: string;
  };
  isloading: boolean;
}
export const StructureIECriteria = (props: Props) => {
  const { cohortCriteria, isloading } = props;
  console.log(cohortCriteria);

  function NewlineText(text: string) {
    const newText = text.split("\n").map((str) => <p key={str}>{str}</p>);
    return newText;
  }

  function convertToCSV(criteria: { inclusion: string; exclusion: string }) {
    let csv = "Inclusion Criteria,Exclusion Criteria\n";
    const inclusionQuestion = criteria.inclusion || "";
    const exclusionQuestion = criteria.exclusion || "";
    csv += `"${inclusionQuestion.replace(/"/g, '""')}","${exclusionQuestion.replace(
      /"/g,
      '""'
    )}"\n`;
    return csv;
  }

  function downloadAsCsv() {
    const csvContent = convertToCSV(cohortCriteria);
    const blob = new Blob([csvContent], { type: "text/csv;charset=utf-8;" });
    const url = URL.createObjectURL(blob);
    const link = document.createElement("a");
    link.setAttribute("href", url);
    link.setAttribute("download", "structured_ie_criteria.csv");
    document.body.appendChild(link);
    link.click();
    document.body.removeChild(link);
  }

  return (
    <div>
      <div className="flex jcsb">
        <h1 className="m-b">Results</h1>
        <div>
          <DownloadOutlined
            onClick={downloadAsCsv}
            title="Export to CSV"
            style={{ fontSize: "21px", marginRight: "1em" }}
          />
          <CopyToClipboard
            text={`Inclusion Criteria :- ${cohortCriteria.inclusion}, Exclusion Criteria :- ${cohortCriteria.exclusion}`}
            onCopy={() => openNotificationWithIcon("", "Text Copied", "success")}
          >
            <ClipboardIcon className="copyIcon" />
          </CopyToClipboard>
        </div>
      </div>
      <div>
        <p>
          <span>
            <h3>Inclusion Criteria:</h3>
          </span>
          {NewlineText(cohortCriteria.inclusion)}
          <br />
          <span>
            <h3>Exclusion Criteria:</h3>
          </span>
          {NewlineText(cohortCriteria.exclusion)}
        </p>
      </div>
    </div>
  );
};
