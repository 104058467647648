import React from "react";
import { Loading } from "../../../components";
import { useSelector } from "react-redux";
import { RootState } from "../../../store";
import "../cohort.scss";
import { CohortPatients } from "../../../pages";

interface Props {
  isDrawerCollapsed: boolean;
  isAnalyzeCohortLoading: boolean;
  displaySelectProjectDropdown?: boolean;
}

export const CriteriaParsingResult = (props: Props) => {
  const { isDrawerCollapsed, isAnalyzeCohortLoading, displaySelectProjectDropdown} = props;
  const { patients, patientsError } = useSelector((state: RootState) => state.cohort);
  const displayResults = !(patients.length === 0 && patientsError.length === 0);

  return (
    <div className={`${displaySelectProjectDropdown? "cohort-max-height": ""} cohort-container w-full ${isDrawerCollapsed ? "drawer-collapsed" : ""}`}>
      
      {isAnalyzeCohortLoading ? (
        <Loading />
      ) : displayResults ? (
        <div>
          <CohortPatients />
        </div>
      ) : (
        <p>
          Currently No Criteria and Cohort Data is generated, please use the form in left to Analyse
          Criteria and Generate Cohort Data.
        </p>
      )}
    </div>
  );
};
