import React from "react";
import { Button, Input } from "antd";
import { useDispatch, useSelector } from "react-redux";
import { RootState, updateExclusionCriteria, updateInclusionCriteria } from "../../../store";

const { TextArea } = Input;

interface Props {
  isAnalyzeCohortLoading: boolean;
  onResetClick: () => void;
  onAnalyzeCohortClick: () => void;
}

export const CriteriaPanel = (props: Props) => {
  const { isAnalyzeCohortLoading, onAnalyzeCohortClick, onResetClick } = props;
  const dispatch = useDispatch();
  const { cohortCriteria } = useSelector((state: RootState) => state.cohort);
  const disableAnalyzeCriteria = !cohortCriteria.inclusion && !cohortCriteria.exclusion;

  return (
    <div>
      <div>
        <p className="cohort-drawer-title">Inclusion Criteria</p>
        <TextArea
          rows={7}
          value={cohortCriteria.inclusion}
          onChange={(e) => dispatch(updateInclusionCriteria(e.target.value))}
        />
      </div>
      <div>
        <p className="cohort-drawer-title m-t-20">Exclusion Criteria</p>
        <TextArea
          rows={7}
          value={cohortCriteria.exclusion}
          onChange={(e) => dispatch(updateExclusionCriteria(e.target.value))}
        />
      </div>
      <div className="flex gp-10 jce m-t m-b">
        <Button className="outline" onClick={() => onResetClick()}>
          Reset
        </Button>
        <Button
          className="fill"
          disabled={disableAnalyzeCriteria}
          loading={isAnalyzeCohortLoading}
          onClick={() => onAnalyzeCohortClick()}
        >
          Analyze Criteria
        </Button>
      </div>
    </div>
  );
};
