import { Input } from "antd";
import { SizeType } from "antd/lib/config-provider/SizeContext";
import React from "react";

const { TextArea } = Input;

export interface ErrorState {
  textInput: string;
}

interface Props {
  onInputTextChange: (e: any) => void;
  error: ErrorState | any;
  inputText: string;
  rows?: number;
  size?: SizeType;
  style?: React.CSSProperties;
}

export const InputText = ({ onInputTextChange, error, inputText, rows, size, style }: Props) => {
  return (
    <>
      <TextArea
        rows={rows ? rows : 8}
        placeholder="Enter text..."
        onChange={(e) => onInputTextChange(e)}
        value={inputText}
        size={size}
        style={style}
      />
      {error.textInput && <p className="error">{error.textInput}</p>}
    </>
  );
};
