export interface CriteriaCategory {
  name: string;
  color: string;
  medical_term: string;
  mapped_concept: string;
  hasConceptCategory: boolean;
}
export const CRITERIA_CATEGORIES: CriteriaCategory[] = [
  {
    name: "Condition",
    color: "#E00084",
    medical_term: "stroke",
    mapped_concept: "Cerebrovascular accident",
    hasConceptCategory: true,
  },
  {
    name: "Drug",
    color: "#43C6FC",
    medical_term: "anti-depressants",
    mapped_concept: "ANTIDEPRESSANTS",
    hasConceptCategory: true,
  },
  {
    name: "Procedure",
    color: "#167CDF",
    medical_term: "general anethesthia",
    mapped_concept: "General anethesthia",
    hasConceptCategory: true,
  },
  {
    name: "Measurement",
    color: "#FD9720",
    medical_term: "MMSE",
    mapped_concept: "mini-mental state examination",
    hasConceptCategory: true,
  },
  {
    name: "Observation",
    color: "#A6E22D",
    medical_term: "orthopedic disorder",
    mapped_concept: "H/O: musculoskeletal disease",
    hasConceptCategory: true,
  },
  {
    name: "Device",
    color: "#00AE56",
    medical_term: "stent",
    mapped_concept: "stent",
    hasConceptCategory: true,
  },
  {
    name: "Demographic",
    color: "#2FBBAB",
    medical_term: "women; age",
    mapped_concept: "",
    hasConceptCategory: false,
  },
  {
    name: "Value",
    color: "#8E7DFF",
    medical_term: ">50; between 14 and 26",
    mapped_concept: "",
    hasConceptCategory: false,
  },
  {
    name: "Temporal",
    color: "#FFCC00",
    medical_term: "over 3 months",
    mapped_concept: "",
    hasConceptCategory: false,
  },
  {
    name: "Negation Cue",
    color: "#FF3333",
    medical_term: "without; no",
    mapped_concept: "",
    hasConceptCategory: false,
  },
];

export const criteriaWithoutConceptCategories = CRITERIA_CATEGORIES.filter(
  (item) => !item.hasConceptCategory
).map((item) => item.name.toLowerCase().split(" ").join("_"));

export const NEGATION = "negation";

export const INCLUSIONS_EXCLUSIONS_EXPORT_OPTIONS: IDropdownOption[] = [
  { id: 1, value: "JSON" },
  { id: 2, value: "Tab-separated values" },
  { id: 3, value: "Comma-separated values" },
  { id: 4, value: "Excel" },
];
