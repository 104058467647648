import { useSelector } from "react-redux";
import { RootState } from "../store";

export const useUser = () => {
  const { user } = useSelector((state: RootState) => state.user);
  const { accessToken, firstName, lastName } = user;

  return {
    firstName,
    lastName,
    accessToken,
    roleCode: user.role ? user.role.code : "",
    roleName: user.role ? user.role.name : "",
  };
};
