import React from "react";
import { hexToRGBColor } from "../../utils";
import "./mark.scss";

interface Props {
  text: string;
  styledText?: JSX.Element;
  textAfterCategory?: string;
  categoryText?: string;
  backgroundColor: string;
}

export const Mark = (props: Props) => {
  const { text, styledText, categoryText, textAfterCategory, backgroundColor } = props;
  return (
    <mark
      className="mark-text"
      style={{ backgroundColor: hexToRGBColor(backgroundColor, 0.2), padding: "0px 4px" }}
    >
      {text}
      {styledText}
      {categoryText && (
        <span
          style={{
            backgroundColor: backgroundColor,
            fontSize: "0.7em",
            fontWeight: 500,
            marginLeft: "6px",
          }}
        >
          {categoryText}
        </span>
      )}
      {textAfterCategory}
    </mark>
  );
};
