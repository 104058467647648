import React, { useEffect, useState } from "react";
import { RootState } from "../store";
import { useDispatch, useSelector } from "react-redux";
import {
  PageWrapper,
  ProjectDetailAside,
  ProjectDetailContainer,
  PROJECT_DETAIL_TABS,
} from "../containers";
import { useParams } from "react-router-dom";
import "../assets/sass/_tabs.scss";
import { isEmptyObject } from "../utils";
import { useProject } from "../hooks";

const ProjectDetail = () => {
  const { projectId } = useParams();
  const dispatch = useDispatch();
  const { getProjects } = useProject();
  const projects = useSelector((state: RootState) => state.project.list);
  // const [selectedTab, setSelectedTab] = useState(PROJECT_DETAIL_TABS.PATIENTS_LIST.name);
  const [loading, setLoading] = useState(false);
  const [projectDetail, setProjectDetail] = useState({} as IProject);

  // const onTabChange = (key: string) => {
  //   setSelectedTab(key);
  // };

  useEffect(() => {
    const fetchProject = async () => {
      setLoading(true);
      // project detail API here
      if (projects.length <= 0) getProjects();
      if (projectId) {
        const index = projects.findIndex((item) => item.id === Number(projectId));
        if (index !== -1) setProjectDetail(projects[index]);
        setLoading(false);
      }
    };
    fetchProject();
  }, [dispatch, getProjects, projectId, projects]);

  return (
    <PageWrapper
      loading={loading || isEmptyObject(projectDetail)}
      // pageLeftAsideChildren={
      //   <ProjectDetailAside {...{ selectedTab, onTabChange, projectName: projectDetail.name }} />
      // }
      pageContainerChildren={<ProjectDetailContainer {...{ projectDetail }} />}
    />
  );
};

export default ProjectDetail;
