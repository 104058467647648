import React from "react";
import { Cohort } from "../../../pages";

interface Props {
  projectId: number;
  projectName: string;
}

export const ProjectDetailEligibilityCriteria = ({ projectId, projectName }: Props) => {

  return (
    <div className="basic-detail-content">
      <Cohort selectedProject={{ id: projectId, value: projectName }} />
    </div>
  );
};
