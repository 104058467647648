import { CloseOutlined } from "@ant-design/icons";
import { Button, Modal } from "antd";
import React, { useEffect, useMemo, useState } from "react";
import { PatientDetailsProps } from ".";
import { cohortService } from "../../../api";
import { Loading } from "../../../components";
import { isEmptyObject, toTitleCase } from "../../../utils";
import { ICriteriaCategory } from "../../cohort/cohort-container/criteriaUtil";
import { PatientDetailCriteriaSection } from "./ListPatientCondition";
import {
  filterDuplicateElements,
  getExclusionHighlightTags,
  getInclusionHighlightTags,
  getMatchedTagsFromPatientDetails,
} from "./patientDetailsUtils";

const PatientDetails = (props: PatientDetailsProps) => {
  const { visibility, handleClose, patientId, inclusion, exclusion } = props;
  const [patientDetails, setPatientDetails] = useState({} as IPatient);
  const [loading, setLoading] = useState(false);
  const [inclusionTags, setInclusionTags] = useState([] as string[]);
  const [exclusionTags, setExclusionTags] = useState([] as ICriteriaCategory[]);

  const { exclusionDetails, inclusionDetails } = useMemo(() => {
    const incDetails = getMatchedTagsFromPatientDetails(inclusionTags, patientDetails);
    const inc = {
      condition: !isEmptyObject(incDetails.conditionOccurrences)
        ? filterDuplicateElements(
            incDetails.conditionOccurrences.map((item) => item.conditionConcept)
          )
        : "",
      procedure: !isEmptyObject(incDetails.procedureOccurrences)
        ? filterDuplicateElements(
            incDetails.procedureOccurrences.map((item) => item.procedureConcept)
          )
        : "",
      measurement: !isEmptyObject(incDetails.measurements)
        ? filterDuplicateElements(incDetails.measurements.map((item) => item.measurementConcept))
        : "",
      observation: !isEmptyObject(incDetails.observation)
        ? filterDuplicateElements(incDetails.observation.map((item) => item.observationConcept))
        : "",
      drug: !isEmptyObject(incDetails.drugExposures)
        ? filterDuplicateElements(incDetails.drugExposures.map((item) => item.drugConcept))
        : "",
    };
    const incRes = [] as { text: string; tag: string }[];
    Object.entries(inc).map(([key, entry]) =>
      entry
        .split(" | ")
        .filter((item) => item)
        .map((item) => incRes.push({ text: item, tag: toTitleCase(key) }))
    );
    return {
      inclusionDetails: incRes,
      exclusionDetails: exclusionTags,
    };
  }, [inclusionTags, patientDetails, exclusionTags]);

  useEffect(() => {
    const fetch = async () => {
      setLoading(true);
      setInclusionTags(getInclusionHighlightTags(inclusion));
      setExclusionTags(getExclusionHighlightTags(exclusion));
      if (patientId) {
        const { data } = await cohortService.getCohortPatientDetails(patientId);
        setPatientDetails(data.data);
      }
      setLoading(false);
    };
    fetch();
  }, [patientId, inclusion, exclusion]);

  return (
    <Modal
      className="autonomizeModal"
      centered
      width={980}
      visible={visibility}
      closeIcon={<CloseOutlined onClick={handleClose} />}
    >
      <div className="addClient-title">
        <h1>Subject Details</h1>
      </div>
      <div className="addClient-content">
        {loading ? (
          <Loading />
        ) : (
          <div className="flex jcsb">
            <PatientDetailCriteriaSection heading="Inclusion" content={inclusionDetails} />
            <PatientDetailCriteriaSection
              heading="Exclusion"
              content={exclusionDetails.map((item) => ({
                tag: toTitleCase(item.tag),
                text: item.mappedConceptText,
              }))}
            />
          </div>
        )}
        <div className="tr m-t">
          <Button className="fill cursor-disable">AI Assisted Chart Review</Button>
        </div>
      </div>
    </Modal>
  );
};

export default PatientDetails;
