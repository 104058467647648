import React from "react";
import { IconsProps } from "./Icons";

export const ListButtonIcon = ({ className, fill, width, height, viewBox }: IconsProps) => {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      className={className}
      viewBox={viewBox ? viewBox : "0 0 16 14"}
      width={width ? width : "16"}
      height={height ? height : "14"}
      fill={fill ? fill : "currentColor"}
    >
      <path
        d="M0 1.05263C0 0.471279 0.447715 0 1 0H15C15.5523 0 16 0.471279 16 1.05263C16 1.63398 15.5523 2.10526 15 2.10526H1C0.447715 2.10526 0 1.63398 0 1.05263Z"
        fill="currentColor"
      />
      <path
        d="M0 6.66667C0 6.08531 0.447715 5.61404 1 5.61404H15C15.5523 5.61404 16 6.08531 16 6.66667C16 7.24802 15.5523 7.7193 15 7.7193H1C0.447715 7.7193 0 7.24802 0 6.66667Z"
        fill="currentColor"
      />
      <path
        d="M0 12.2807C0 11.6993 0.447715 11.2281 1 11.2281H15C15.5523 11.2281 16 11.6993 16 12.2807C16 12.8621 15.5523 13.3333 15 13.3333H1C0.447715 13.3333 0 12.8621 0 12.2807Z"
        fill="currentColor"
      />
    </svg>
  );
};
