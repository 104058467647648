export function isEmptyObject(obj: any) {
  try {
    if (typeof obj === "undefined" || obj === null || Object.keys(obj).length == 0) {
      return true;
    }
    return false;
  } catch (e) {
    return true;
  }
}
