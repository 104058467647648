import React, { useEffect, useState } from "react";
import { Loading } from "../../../../components";
import TasksApi from "../../../../api/tasks.api";
import { openNotificationWithIcon } from "../../../../utils";
import CopyToClipboard from "react-copy-to-clipboard";
import { Button } from "antd";
import { ClipboardIcon } from "../../../../assets/icons";
import { convertToCSV, parseCriteriaText } from "./helper";
import { DownloadOutlined } from "@ant-design/icons";

interface Props {
  isLoading: boolean;
  setIsLoading: React.Dispatch<React.SetStateAction<boolean>>;
  query: string;
}

export const SimplifyIECriteria = ({
  isLoading,
  setIsLoading,
  query,
}: Props) => {
  const [queryResponse, setQueryResponse] = useState("");

  useEffect(() => {
    const getTask = async () => {
      try {
        if (query) {
          setIsLoading?.(true);
          const { data } = await new TasksApi().protocolICECSummarizer(query);
          setQueryResponse(data?.["results"] || ""); // add a check for data
        }
      } catch (e: any) {
        openNotificationWithIcon("", e.response?.data?.message || "An error occurred", "error");
        setQueryResponse("");
      } finally {
        setIsLoading(false);
      }
    };
    getTask();
  }, [query, setIsLoading]);

  function downloadAsCsv() {
    const criteria = parseCriteriaText(queryResponse[0]);
    const csvContent = convertToCSV(criteria);
    const blob = new Blob([csvContent], { type: "text/csv;charset=utf-8;" });
    const url = URL.createObjectURL(blob);
    const link = document.createElement("a");
    link.setAttribute("href", url);
    link.setAttribute("download", "simplified_ie_criteria.csv");
    document.body.appendChild(link);
    link.click();
    document.body.removeChild(link);
  }

  return (
    <>
      <div className="flex jcsb">
        <h1 className="m-b">Result</h1>
        <div>
          <DownloadOutlined
            onClick={downloadAsCsv}
            title="Export to CSV"
            style={{ fontSize: "21px", marginRight: "1em" }}
          />
          <CopyToClipboard
            text={queryResponse}
            onCopy={() => openNotificationWithIcon("", "Text Copied", "success")}
          >
            <ClipboardIcon className="copyIcon" />
          </CopyToClipboard>
        </div>
      </div>
      <br />
      {isLoading ? (
        <Loading />
      ) : queryResponse ? (
        <>
          <div>
            <h3>Inclusion Criteria</h3>
            {parseCriteriaText(queryResponse[0])?.inclusion.map((item, index) => (
              <div key={index} className="query-response" style={{ whiteSpace: "pre-line" }}>
                {index + 1}. {item}
              </div>
            ))}
            <br />
            <h3>Exclusion Criteria</h3>
            {parseCriteriaText(queryResponse[0])?.exclusion.map((item, index) => (
              <div key={index} className="query-response" style={{ whiteSpace: "pre-line" }}>
                {index + 1}. {item}
              </div>
            ))}
            {/* <div className="query-response" style={{ whiteSpace: "pre-line" }}>{parseCriteriaText(queryResponse[0])?.exclusion || ''}</div> */}
          </div>
          <div className="tr m-t"></div>
          <br />
        </>
      ) : (
        <div>Please enter text to get response from the model.</div>
      )}
    </>
  );
};
