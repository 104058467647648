import React from "react";
import { Modal } from "antd";
import { Button } from "antd";
import { DeleteConfirmationProps } from ".";
import { CloseOutlined } from "@ant-design/icons";
import "../modal.scss";

const DeleteConfirmation = ({ visibility, handleClose, handleDelete }: DeleteConfirmationProps) => {
  const handleDeleteConfirmation = () => {
    handleDelete();
    handleClose();
  };
  return (
    <Modal
      className="autonomizeModal"
      centered
      width={587}
      title="Delete MOdal"
      visible={visibility}
      footer={null}
      closeIcon={<CloseOutlined onClick={handleClose} />}
    >
      <div>
        <div className="addClient-title">
          <h1>Delete Confirmation</h1>
        </div>
        <div className="deleteConfirmation tc">
          <p>
            Do you really want to delete this record ?<br /> This process cannot be undone.
          </p>
          <div className="flex jcc gp">
            <Button type="primary" className="outline" onClick={handleClose}>
              Cancel
            </Button>
            <Button type="primary" className="outline" onClick={handleDeleteConfirmation}>
              Delete
            </Button>
          </div>
        </div>
      </div>
    </Modal>
  );
};

export default DeleteConfirmation;
