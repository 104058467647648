import React, { useCallback } from "react";
import { AddEditProjectForm } from "../modals/add-edit-project/AddEditProjectForm";
import { EligibilityChecker } from "../../assets/images/";
import { useDispatch } from "react-redux";
import { openModal } from "../../store";
import { MODAL_TYPE } from "../../utils";
import "./eligibility-checker.scss";

export const CreateFirstProjectContainer = () => {
  const dispatch = useDispatch();
  const openUploadPatientDataset = useCallback(() => {
    dispatch(openModal(MODAL_TYPE.UPLOAD_PATIENT_DATASET));
  }, [dispatch]);

  return (
    <div className="eligibility-checker-dashboard">
      <h1>Create your first project here</h1>
      <div className="first-project m-t">
        <AddEditProjectForm
          editObj={{} as IProject}
          isEdit={false}
          openUploadPatientDataset={openUploadPatientDataset}
        />
        {/* <div className="first-project-img">
          <img src={EligibilityChecker} alt="Eligibility Checker Image" />
        </div> */}
      </div>
    </div>
  );
};
