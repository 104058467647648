import React from "react";
import { IconsProps } from "./Icons";

export const CohortCollapseIcon = ({
  className,
  fill,
  width,
  height,
  viewBox,
  onClick,
}: IconsProps) => {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      className={className}
      viewBox={viewBox ? viewBox : "0 0 10 13"}
      width={width ? width : "10"}
      height={height ? height : "13"}
      fill={fill ? fill : "currentColor"}
      onClick={onClick}
    >
      <path
        xmlns="http://www.w3.org/2000/svg"
        d="M9.30791 1.14377C9.58663 1.57862 9.48833 2.15418 9.08108 2.47187L4.68556 5.90073C4.17232 6.30109 4.17232 7.0773 4.68556 7.47767L9.08108 10.9065C9.48833 11.2242 9.58663 11.7998 9.30791 12.2346L9.1668 12.4548C8.84909 12.9505 8.17404 13.0658 7.70982 12.7036L1.01056 7.47767C0.497325 7.0773 0.497323 6.30109 1.01056 5.90073L7.70982 0.674767C8.17404 0.312639 8.84909 0.427934 9.1668 0.923611L9.30791 1.14377Z"
        fill="CurrentColor"
      />
    </svg>
  );
};
