import React, { useState } from "react";
import { Input, Modal, UploadFile } from "antd";
import { UploadPatientDatasetProps } from ".";
import { CloseOutlined } from "@ant-design/icons";
import { FileUploader } from "../../../components";
import "../modal.scss";
import { UploadData } from "../../../assets/images";

const UploadPatientDataset = ({ visibility, handleClose }: UploadPatientDatasetProps) => {
  const [datasetName, setDatasetName] = useState("");

  const onDatasetNameChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    setDatasetName(e.target.value);
  };

  const onFileSubmit = async (file: UploadFile<any>) => {};

  return (
    <Modal
      className="autonomizeModal addClient"
      centered
      width={587}
      title="Upload Patient Dataset"
      visible={visibility}
      footer={null}
      closeIcon={<CloseOutlined onClick={handleClose} />}
    >
      <div className="addClient-title">
        <h1>Connect to New Source</h1>
      </div>
      <div className="addClient-content">
        {/* <h1 className="m-b">Upload Patient Dataset</h1> */}
        <h3>Dataset Name</h3>
        <Input value={datasetName} onChange={onDatasetNameChange} />
        {/* {!datasetName && <p className="error">{"This field is required"}</p>} */}
        <div className="m-t">
          {/* <FileUploader
            onFileSubmit={onFileSubmit}
            formats={[".csv", ".json"]}
            isUploadButtonDisabled={!datasetName}
          /> */}
          <img className="w-full" src={UploadData} alt="upload_data" />
        </div>
      </div>
    </Modal>
  );
};

export default UploadPatientDataset;
