import React, { Dispatch } from "react";
import Table, { ColumnsType } from "antd/lib/table";
import { useDispatch } from "react-redux";
import { openModal, updateCohortPatientId } from "../../store";
import { MODAL_TYPE } from "../../utils";
import { AnyAction } from "@reduxjs/toolkit";
import "./cohort-patients.scss";

const getColumns = (
  dispatch: Dispatch<AnyAction>,
  isCohortPatientViewDetailBtnAvailable: boolean
): ColumnsType<ICohortPatientTableDataType> =>
  [
    {
      title: "Subject ID",
      dataIndex: "patientId",
    },
    {
      title: "Date of Birth",
      dataIndex: "dob",
    },
    {
      title: "Age",
      dataIndex: "age",
    },
    {
      title: "Gender",
      dataIndex: "gender",
    },
    {
      title: "Race",
      dataIndex: "race",
    },
    {
      title: "Details",
      dataIndex: "details",
      render: (value: string, record: ICohortPatientTableDataType) => (
        <a
          onClick={() => {
            dispatch(updateCohortPatientId(record.patientId));
            dispatch(openModal(MODAL_TYPE.PATIENT_DETAILS));
          }}
        >
          View More
        </a>
      ),
      hidden: !isCohortPatientViewDetailBtnAvailable,
    },
  ].filter((item) => !item.hidden);

interface Props {
  data: ICohortPatientTableDataType[];
  isCohortPatientViewDetailBtnAvailable?: boolean;
  loading?: boolean;
}

export const CohortPatientsTable = ({
  data,
  loading,
  isCohortPatientViewDetailBtnAvailable,
}: Props) => {
  const dispatch = useDispatch();

  return (
    <Table
      className="basic-detail-content user-table"
      loading={loading}
      pagination={false}
      dataSource={data}
      columns={getColumns(dispatch, !!isCohortPatientViewDetailBtnAvailable)}
    />
  );
};
