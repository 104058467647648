import React from "react";
import { useSelector } from "react-redux";
import { CohortPatientsContainer, PageWrapper } from "../containers";
import { RootState } from "../store";

const CohortPatients = () => {
  const { patients, patientsError } = useSelector((state: RootState) => state.cohort);

  return (
    <PageWrapper
      pageContainerChildren={
        <CohortPatientsContainer patients={patients} patientsError={patientsError} />
      }
    />
  );
};

export default CohortPatients;
