import { Switch } from "antd";
import React from "react";
import { useDispatch } from "react-redux";
import { Link } from "react-router-dom";
import { EditIcon } from "../../../assets/icons";
import { useProject } from "../../../hooks";
import { setProjectEditDelete, openModal } from "../../../store";
import { openNotificationWithIcon, PAGE_URL, MODAL_TYPE } from "../../../utils";

interface Props {
  record: IProject;
  displayViewMore?: boolean;
}

export const ProjectActions = ({ record, displayViewMore }: Props) => {
  const dispatch = useDispatch();
  const { editProject, isProjectEditLoading } = useProject();
  const onChange = async (isActive: boolean) => {
    try {
      await editProject({ isActive }, record.id);
    } catch (err: any) {
      openNotificationWithIcon("", "Not able to update status", "error");
    }
  };
  return (
    <div className="editDelete flex gp">
      {displayViewMore && <Link to={`${PAGE_URL.PROJECT_DETAIL}/${record.id}`}>View More</Link>}
      <EditIcon
        className={record.isActive ? "cursor-pointer" : "cursor-disable"}
        onClick={() => {
          if (record.isActive) {
            dispatch(setProjectEditDelete({ id: record.id, isEdit: true }));
            dispatch(openModal(MODAL_TYPE.ADD_EDIT_PROJECT));
          }
        }}
      />
      <Switch
        size="small"
        checked={record.isActive}
        onChange={onChange}
        loading={isProjectEditLoading}
      />
    </div>
  );
};
