import React from "react";
import { PageWrapper, SearchAside, SearchContainer } from "../containers";

const Search = () => {
  return (
    <PageWrapper
      pageContainerChildren={<SearchContainer />}
      pageLeftAsideChildren={<SearchAside />}
    />
  );
};

export default Search;
