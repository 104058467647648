export const SEARCH_ITEMS_STORAGE = "searchItems";
export const searchItemsStorageEvent = new StorageEvent(SEARCH_ITEMS_STORAGE, {
  bubbles: false,
  cancelable: false,
  key: "k1",
  oldValue: "oldValue",
  newValue: "newValue",
  url: "",
  storageArea: window.localStorage,
});
window.dispatchEvent(searchItemsStorageEvent);
