import React from "react";
import { MapWithMark } from "../../../assets/images";

export const Map = () => {
  return (
    <div>
      <img className="w-full" src={MapWithMark} alt="Location Map" />
    </div>
  );
};
