export const PAGE_URL = {
  LOGIN: "/",
  RESET_PASSWORD: "/reset-password",
  RESET_PASSWORD_SUCCESS: "/reset-password-success",
  CREATE_PASSWORD: "/password-create",
  CREATE_PASSWORD_SUCCESS: "/password-create-success",
  PROJECTS: "/projects",
  PROJECT_DETAIL: "/project/detail",
  COHORT: "/cohort",
  COHORT_PATIENTS: "/cohort/patients",
  SEARCH: "/search",
};

export const PAGE_TITLE = [
  {
    route: PAGE_URL.COHORT,
    title: "AI Powered Cohort Selection & Feasibility",
    displayBackButton: false,
  },
  { route: PAGE_URL.PROJECTS, title: "Projects", displayBackButton: false },
  { route: PAGE_URL.PROJECT_DETAIL, title: "Project Detail", displayBackButton: true },
  { route: PAGE_URL.COHORT_PATIENTS, title: "Matched List of Patients", displayBackButton: true },
  {
    route: PAGE_URL.SEARCH,
    title: "AI Powered Cohort Selection & Feasibility",
    displayBackButton: false,
  },

  // { route: PAGE_URL.PATIENT_PROFILE, title: "Patient Insights" },
  // { route: PAGE_URL.PATIENTS_LIST, title: "Patients" },
  //   { route: PAGE_URL.SEARCH.LAYOUT, title: "Search" },
  //   { route: PAGE_URL.SETTINGS, title: "Settings" },
  //   { route: PAGE_URL.EVIDENCE_VIEWER, title: "Evidence Viewer" },
  //   { route: PAGE_URL.ROLE_MAPPING, title: "Roles Mapping" },
  //   { route: PAGE_URL.CONFIGURATIONS, title: "Configurations" },
  //   { route: PAGE_URL.ANNOTATION, title: "Upload File for Annotation" },
  //   { route: PAGE_URL.ANNOTATE_FILE, title: "Annotate File" },
];
