import { criteriaWithoutConceptCategories, CRITERIA_CATEGORIES } from "../../../utils";

export interface ICriteriaCategory {
  tag: string;
  color: string;
  text: string;
  mappedConceptText: string;
}

interface FunctionParams {
  criteria: string;
  removeHighlight?: boolean;
  updateHighlights?: (data: string[]) => void;
}

export const getCriteriaEntry = ({
  criteria,
  removeHighlight,
  updateHighlights,
}: FunctionParams) => {
  const div = document.createElement("div");
  div.innerHTML = criteria;
  const criteriaText = div.innerText;
  const groups = criteria.match(/<mark.*?>*?<\/mark>/g);
  const highlights: ICriteriaCategory[] = [];
  if (groups && groups.length > 0) {
    for (let i = 0; i < groups.length; i++) {
      const matchedEntityString = groups[i].match(/data-entity="(.*?)"/);
      let entity = "";
      if (matchedEntityString && matchedEntityString.length >= 2) entity = matchedEntityString[1];
      let entityColor = "#505EDA";
      const entityColorIndex = CRITERIA_CATEGORIES.findIndex(
        (item) => item.name.split(" ").join("_").toLowerCase() === entity
      );
      if (entityColorIndex !== -1) entityColor = CRITERIA_CATEGORIES[entityColorIndex].color;
      if (criteriaWithoutConceptCategories.includes(entity)) {
        const matchedMarkText = groups[i].match(/">(.*?)<\/mark>/);
        if (matchedMarkText && matchedMarkText.length >= 2)
          highlights.push({
            color: entityColor,
            mappedConceptText: "",
            tag: entity,
            text: matchedMarkText[1],
          });
      } else {
        const items = groups[i].match(/concept-id="(.*?)">(.*?)<b><i>(.*?)<\/i><\/b>/);
        if (items && items.length >= 4) {
          highlights.push({
            color: entityColor,
            mappedConceptText: items[3],
            tag: entity,
            text: items[2],
          });
        }
      }
    }
  }
  let content = criteriaText;
  // updateHighlights && updateHighlights(highlights.map((item) => item.mappedConceptText));
  highlights.forEach((highlight) => {
    const highlightText = highlight.text + highlight.mappedConceptText;
    const highlightHtml = `<span style='background-color:${
      highlight.color
    }33;color:#111;margin:5px;border-radius:4px;padding:5px;'>${highlight.text}${
      highlight.mappedConceptText ? `<b><i>${highlight.mappedConceptText}</i></b>` : ""
    }<span style='font-family:Font-Bold;background-color:${
      highlight.color
    };font-size:0.7em;font-weight:500;padding:3px;margin:0 5px;border-radius:4px;color:#fff;'>${highlight.tag.toUpperCase()}</span></span>`;
    if (removeHighlight) content = content.replace(highlightText, highlight.text);
    else content = content.replace(highlightText, highlightHtml);
  });
  return { content, highlights };
};
