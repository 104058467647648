import React, { Dispatch, SetStateAction, useEffect, useState } from "react";
import Table, { ColumnsType } from "antd/lib/table";
import { useDispatch, useSelector } from "react-redux";
import { AnyAction } from "redux";
import { PROJECT_DETAIL_TABS } from "..";
import { useSavedCohorts } from "../../../hooks";
import {
  openModal,
  RootState,
  updateAnalyzeExclusionCriteria,
  updateAnalyzeInclusionCriteria,
  updateDeleteConfirmation,
  updateExclusionCriteria,
  updateInclusionCriteria,
  updateNctId,
  updatePatients,
  updatePatientsError,
} from "../../../store";
import { getLocalDateTimeStringFromISO, MODAL_TYPE } from "../../../utils";
import { DeleteIcon } from "../../../assets/icons";

const getColumns = (
  dispatch: Dispatch<AnyAction>,
  setSelectedTab: Dispatch<SetStateAction<string>>,
  setDeleteCohortId: Dispatch<SetStateAction<number>>
): ColumnsType<ISavedCohortTableDataType> => [
  {
    title: "Name",
    dataIndex: "name",
  },
  {
    title: "Description",
    dataIndex: "description",
  },
  {
    title: "Saved On",
    dataIndex: "savedOn",
  },
  {
    title: "Details",
    dataIndex: "details",
    render: (value: string, record: ISavedCohortTableDataType) => (
      <div className="flex aic gp-10">
        <a
          onClick={() => {
            const { nctId, aiAssistedCriteria, criteria } = record.metaData;
            dispatch(updateNctId(nctId));
            dispatch(updateInclusionCriteria(criteria.inclusion));
            dispatch(updateExclusionCriteria(criteria.exclusion));
            dispatch(
              updateAnalyzeInclusionCriteria(
                aiAssistedCriteria.inclusion.map((item) => ({
                  ...item,
                  ehrstatus: item.isSelected,
                }))
              )
            );
            dispatch(
              updateAnalyzeExclusionCriteria(
                aiAssistedCriteria.exclusion.map((item) => ({
                  ...item,
                  ehrstatus: item.isSelected,
                }))
              )
            );
            dispatch(updatePatients([]));
            dispatch(updatePatientsError(""));
            setSelectedTab(PROJECT_DETAIL_TABS.SITE_FEASIBILITY.name);
          }}
        >
          View More
        </a>
        <DeleteIcon
          className="cursor-pointer"
          onClick={() => {
            setDeleteCohortId(record.id);
            dispatch(openModal(MODAL_TYPE.DELETE_CONFIRMATION));
          }}
        />
      </div>
    ),
  },
];

interface Props {
  projectId: number;
  setSelectedTab: Dispatch<SetStateAction<string>>;
}

export const ProjectDetailSavedCohort = ({ projectId, setSelectedTab }: Props) => {
  const dispatch = useDispatch();
  const { getAllCohortsByProjectId, loading, savedCohorts, deleteSavedCohort } = useSavedCohorts();
  const { isDeleteConfirmed } = useSelector((state: RootState) => state.ui);
  const [deleteCohortId, setDeleteCohortId] = useState(-1);

  useEffect(() => {
    if (deleteCohortId !== -1 && isDeleteConfirmed) {
      deleteSavedCohort(deleteCohortId, projectId);
      dispatch(updateDeleteConfirmation(false));
    }
  }, [deleteCohortId, deleteSavedCohort, dispatch, isDeleteConfirmed, projectId]);

  const dataSource: ISavedCohortTableDataType[] = savedCohorts.map((item) => ({
    key: `saved-cohort-${item.id}`,
    id: item.id,
    name: item.name,
    description: item.description,
    savedOn: getLocalDateTimeStringFromISO(item.createdAt),
    metaData: JSON.parse(item.metaData),
  }));

  useEffect(() => {
    getAllCohortsByProjectId(projectId);
  }, [getAllCohortsByProjectId, projectId]);

  return (
    <Table
      className="basic-detail-content user-table"
      loading={loading}
      pagination={false}
      dataSource={dataSource}
      columns={getColumns(dispatch, setSelectedTab, setDeleteCohortId)}
    />
  );
};
