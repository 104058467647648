import React from "react";
import { Button } from "antd";
import { useNavigate } from "react-router-dom";

interface Props {
  err: string;
  onRetryClick: () => void;
}

export const ErrorLoadingCohortPatients = ({ err, onRetryClick }: Props) => {
  const navigate = useNavigate();
  return (
    <div className="tc p-20">
      <h3>{err}</h3>
    </div>
  );
};
