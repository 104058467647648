import { Button } from "antd";
import React from "react";
import { useDispatch } from "react-redux";
import { CohortPatientsExport, CohortPatientsTable } from "../../components";
import { openModal } from "../../store";
import { MODAL_TYPE } from "../../utils";
import { ErrorLoadingCohortPatients } from "./ErrorLoadingCohortPatients";

interface Props {
  patients: InclusionExclusionCriteriaPatientRecord[];
  patientsError: string;
}

export const CohortPatientsContainer = ({ patients, patientsError }: Props) => {
  const dispatch = useDispatch();
  const dataSource: ICohortPatientTableDataType[] = patients.map((item) => {
    const { age, birth_date, gender, person_id, race } = item;
    return {
      age,
      dob: birth_date,
      gender,
      key: `patient-${person_id}`,
      patientId: person_id,
      race,
    };
  });

  return (
    <div>
      {/* <div className="flex gp">
          <div className="nctid-container">
            <b>NCTID </b>
            <p>{nctId ? nctId : "-"}</p>
          </div>
          <div className="selected-project-container">
            <b>Project</b>
            <p>{selectedProject.value}</p>
          </div>
        </div> */}
      <div className="flex gp aic jcsb">
        <div className="flex gp aic">
          {/* <Button
            className="outline"
            onClick={() => {
              dispatch(openModal(MODAL_TYPE.CRITERIA_AI_ASSISTED_VIEW));
            }}
          >
            {"Filter Criteria (AI Assisted View)"}
          </Button> */}
          <Button
            className="outline"
            onClick={() => {
              dispatch(openModal(MODAL_TYPE.CRITERIA_SQL_QUERY));
            }}
          >
            View Query
          </Button>
          <Button
            className="outline"
            onClick={() => {
              dispatch(openModal(MODAL_TYPE.SAVE_COHORT));
            }}
          >
            Save Query
          </Button>
        </div>
        <CohortPatientsExport data={dataSource} />
      </div>
      {patientsError ? (
        <ErrorLoadingCohortPatients err={patientsError} onRetryClick={() => {}} />
      ) : (
        <CohortPatientsTable data={dataSource} isCohortPatientViewDetailBtnAvailable />
      )}
    </div>
  );
};
