import React from "react";
import { PAGE_URL, USER_ROLES_CODE } from "../../../utils";
import { HomeIcon, CohortIcon, SearchIcon } from "../../../assets/icons";

const CLINIC_MENU_ITEMS: ILeftMenuItem[] = [
  {
    link: PAGE_URL.PROJECTS,
    icon: <HomeIcon />,
    width: 18,
    height: 20,
    title: "Projects",
  },
  {
    link: PAGE_URL.COHORT,
    icon: <CohortIcon />,
    width: 20,
    height: 20,
    title: "Cohort",
  },
  {
    link: PAGE_URL.SEARCH,
    icon: <SearchIcon />,
    width: 18,
    height: 20,
    title: "Search",
  },
];

const CRO_MENU_ITEMS: ILeftMenuItem[] = [
  {
    link: PAGE_URL.PROJECTS,
    icon: <HomeIcon />,
    width: 18,
    height: 20,
    title: "Projects",
  },
  {
    link: PAGE_URL.SEARCH,
    icon: <SearchIcon />,
    width: 18,
    height: 20,
    title: "Search",
  },
];

export const getAppMenuItems = (roleCode: string) => {
  const menu = [] as ILeftMenuItem[];
  switch (roleCode) {
    case USER_ROLES_CODE.CLINIC:
      menu.push(...CLINIC_MENU_ITEMS);
      break;
    case USER_ROLES_CODE.CRO:
      menu.push(...CRO_MENU_ITEMS);
      break;
  }
  return menu;
};
