export * from "./ProjectDetailAside";
export * from "./ProjectDetailContainer";

export const PROJECT_DETAIL_TABS = {
  OVERVIEW: { name: "Overview" },
  // PATIENTS_LIST: { name: "List of Subjects" },
  PROTOCOL_INSIGHTS: { name: "Protocol Insights" },
  SITE_FEASIBILITY: { name: "Feasibility" },
  SAVED_COHORTS: { name: "Saved Queries" },
};
