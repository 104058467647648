//boilerplate code for upload container
import React, { useState } from "react";
import { Button, Progress, UploadProps, message } from "antd";
import { UploadFile } from "antd/lib/upload";
import { FileUploader, TaskInput } from "../../../components";
import { CloudUploadOutlined, UploadOutlined } from "@ant-design/icons";
// import "./upload.scss";
import { isEmptyObject, openNotificationWithIcon } from "../../../utils";
import { parseCSVFile } from "../../../components/task-input/csvParser";
import { uploadFileFolderService } from "../../../api";
import Dragger from "antd/lib/upload/Dragger";
import { useParams } from "react-router-dom";
import { useDispatch } from "react-redux";
import {
  updateAnalyzeExclusionCriteria,
  updateAnalyzeInclusionCriteria,
  updateExclusionCriteria,
  updateInclusionCriteria,
} from "../../../store";

interface Props {
  loading: boolean;
  setLoading: any;
  setDataExtracted: React.Dispatch<React.SetStateAction<string>>;
  setIeSimplificationInput: React.Dispatch<React.SetStateAction<string>>;
  setViewFile: React.Dispatch<React.SetStateAction<UploadFile<any>[]>>;
}

const acceptedFormats = [".pdf"];

export const UploadContainer = ({
  loading,
  setLoading,
  setDataExtracted,
  setIeSimplificationInput,
  setViewFile,
}: Props) => {
  const [files, setFiles] = useState([] as UploadFile<any>[]);
  const [pollingCounter, setPollingCounter] = useState<number>(0);
  const { projectId } = useParams();
  const dispatch = useDispatch();

  const props: UploadProps = {
    name: "file",
    multiple: true,
    accept: acceptedFormats.join(","),
    beforeUpload: (file, fileList) => {
      const isImage = ["image/jpeg", "image/png", "image/jpg"].includes(file.type);
      if (isImage) message.error("Image upload not supported!");
      else {
        setFiles([...fileList, ...files]);
        setViewFile([...fileList, ...files]);
      }
      return isImage;
    },
    onRemove: (file: UploadFile<any>) => {
      if (loading) {
        message.error("Cannot remove file during upload!");
      } else {
        const fileIndex = files.findIndex((item) => item.name === file.name);
        if (fileIndex !== -1) {
          setFiles([...files.slice(0, fileIndex), ...files.slice(fileIndex + 1)]);
        }
      }
    },
    fileList: files,
  };

  const handleFilesSubmit = async (e: React.MouseEvent<HTMLButtonElement>) => {
    e.stopPropagation();
    for (let i = 0; i < files.length; i++) {
      try {
        const filePath = `${files[i].name}`;
        if (projectId) {
          const firstProgress = Math.ceil((((i + 1) / files.length) * 100) / 3);
          setPollingCounter(firstProgress);
          const { data } = await uploadFileFolderService.getUploadSignedUrl(+projectId, filePath);
          setPollingCounter(2 * firstProgress);
          await uploadFileFolderService.uploadFiles(data.data.signedUrl, files[i]);
          const { data: response } = await uploadFileFolderService.startFileProcessing(
            +projectId,
            filePath,
            data.data.signedUrl
          );
          setPollingCounter(3 * firstProgress);
          setDataExtracted(response.data);
          openNotificationWithIcon("", `${files[i].name} uploaded successfully!`, "success");
          setFiles([]);
        }
      } catch (err: any) {
        openNotificationWithIcon("", err.response.data.message, "error");
      } finally {
        setFiles([]);
      }
    }
    setPollingCounter(0);
  };

  // const onFileSubmit = async (file: any) => {
  //     try{
  //       // const inputData = await parseCSVFile(file);
  //       // console.log(file);
  //       // console.log(inputData);

  //     }
  //     catch(e: any){
  //       message.error(e.message);
  //     }
  //   };
  // return(

  //     <><p className="m-b">Upload File</p>
  //     <FileUploader onFileSubmit={onFileSubmit} formats={acceptedFormats} multipleFiles={false} /></>

  // );

  return (
    <div className="attachment">
      <Dragger {...props}>
        <div className="attachment-uploadbtn">
          <div className="attachment-content">
            <CloudUploadOutlined style={{ fontSize: "60px" }} />
            <div className="attachment-content-text">
              <p>
                Drag & drop file here or <span>Browse File</span>
              </p>
              {acceptedFormats.length > 0 && (
                <p className="italic">You can upload only {acceptedFormats.join(", ")} files</p>
              )}
            </div>
          </div>
          {/* <Button
              style={{ marginTop: "12px" }}
              className="fill"
              onClick={handleFilesSubmit}
              disabled={files.length === 0}
            >
              Upload
            </Button> */}
        </div>
      </Dragger>
      {pollingCounter !== 0 && (
        <div style={{ display: "flex" }}>
          <Progress
            status="active"
            strokeLinecap="round"
            type="line"
            style={{ marginBottom: "4px" }}
            percent={pollingCounter}
          />
        </div>
      )}
      <div style={{ width: "100%", display: "flex", justifyContent: "end" }}>
        <Button
          style={{ marginTop: "12px" }}
          className="fill"
          onClick={handleFilesSubmit}
          disabled={files.length === 0 || pollingCounter !== 0}
        >
          Upload
        </Button>
      </div>
    </div>
  );
};
