interface StudyFields {
    BriefTitle: string;
    BriefSummary: string;
    DetailedDescription: string;
    EligibilityCriteria: string;
}

export async function fetchStudyFields(input:string): Promise<StudyFields> {
    const URL = `https://classic.clinicaltrials.gov/api/query/study_fields?expr=${input}&fields=BriefTitle,BriefSummary,DetailedDescription,EligibilityCriteria&min_rnk=1&max_rnk=&fmt=json`
    const response = await fetch(URL);
    const data = await response.json();
    const studyFields: StudyFields = {
        BriefTitle: data.StudyFieldsResponse.StudyFields[0].BriefTitle,
        BriefSummary: data.StudyFieldsResponse.StudyFields[0].BriefSummary,
        DetailedDescription: data.StudyFieldsResponse.StudyFields[0].DetailedDescription,
        EligibilityCriteria: data.StudyFieldsResponse.StudyFields[0].EligibilityCriteria,

    };

    return studyFields;
}

// // interface StudyFields {
// //     EligibilityCriteria: string;
// // }

// export async function fetchCriteriaFields(input:string): Promise<string>{
//     const URL = `https://clinicaltrials.gov/api/query/study_fields?expr=${input}&fields=EligibilityCriteria&min_rnk=1&max_rnk=&fmt=json`
//     const response = await fetch(URL);
//     const data = await response.json();
//     const EligibilityCriteria = data.StudyFieldsResponse.StudyFields[0].EligibilityCriteria;

//     return EligibilityCriteria;
// }

