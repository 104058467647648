import { combineReducers } from "redux";

import uiReducer, { uiInitialState } from "./ui/slice";
import userReducer, { userInitialState } from "./user/slice";
import projectReducer, { projectSliceInitialState } from "./project/slice";
import cohortReducer, { cohortInitialState } from "./cohort/slice";
import protocolInsightsReducer, {
  protocolinsightsInitialState,
} from "./protocol-insights/InsightsSlice";
import documentSlice, { documentSliceInitialState } from "./document/slice";

export const initialState = {
  ui: uiInitialState,
  user: userInitialState,
  project: projectSliceInitialState,
  cohort: cohortInitialState,
  insights: protocolinsightsInitialState,
  document: documentSliceInitialState,
};

export default function createReducer(injectedReducers = {}) {
  const rootReducer = combineReducers({
    ui: uiReducer,
    user: userReducer,
    project: projectReducer,
    cohort: cohortReducer,
    insights: protocolInsightsReducer,
    document: documentSlice,
    ...injectedReducers,
  });

  return rootReducer;
}
