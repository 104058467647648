import { useCallback, useState } from "react";
import { useDispatch } from "react-redux";
import { projectService } from "../api";
import { PATIENT_DATASET_OPTIONS } from "../containers/modals/add-edit-project/AddEditProjectForm";
import { addProject, setProjects, updateProject } from "../store";
import { USER_ROLES_CODE } from "../utils";
import { useUser } from "./useUser";

export const useProject = () => {
  const dispatch = useDispatch();
  const [isProjectsLoading, setIsProjectsLoading] = useState(false);
  const [isProjectCreateLoading, setIsProjectCreateLoading] = useState(false);
  const [isProjectEditLoading, setIsProjectEditLoading] = useState(false);
  const { roleCode } = useUser();

  const getProjects = useCallback(async () => {
    setIsProjectsLoading(true);
    const { data } = await projectService.getAll();
    dispatch(
      setProjects(
        data.data.projects.map((item) => ({
          ...item,
          patientDataset: PATIENT_DATASET_OPTIONS[1],
        }))
      )
    );
    setIsProjectsLoading(false);
  }, [dispatch]);

  const createProject = useCallback(
    async (obj: IProjectCreate) => {
      setIsProjectCreateLoading(true);
      const { data } = await projectService.create(obj);
      if(roleCode === USER_ROLES_CODE.CRO){
        dispatch(addProject({ ...data.data, patientDataset: PATIENT_DATASET_OPTIONS[0] }));
      }else{
        dispatch(addProject({ ...data.data, patientDataset: PATIENT_DATASET_OPTIONS[1] }));
      }
      setIsProjectCreateLoading(false);
    },
    [dispatch, roleCode]
  );

  const editProject = useCallback(
    async (obj: IProjectUpdate, id: number) => {
      setIsProjectEditLoading(true);
      await projectService.update(obj, id);
      dispatch(updateProject({ obj, id }));
      setIsProjectEditLoading(false);
    },
    [dispatch]
  );

  return {
    getProjects,
    createProject,
    editProject,
    isProjectCreateLoading,
    isProjectEditLoading,
    isProjectsLoading,
  };
};
