import { Dispatch } from "redux";
import { connect } from "react-redux";
import SaveCohort from "./SaveCohort";
import { closeModal, RootState } from "../../../store";
import { MODAL_TYPE } from "../../../utils";

type StateProps = {
  visibility: boolean;
  metaData: ISaveCohortMetaData;
  projectId: number;
};
type DispatchProps = {
  handleClose: () => void;
};
export type SaveCohortProps = StateProps & DispatchProps;

const mapStateToProps = (state: RootState): StateProps => ({
  visibility: state.ui.modal[MODAL_TYPE.SAVE_COHORT],
  projectId: state.cohort.selectedProject.id,
  metaData: {
    nctId: state.cohort.nctId,
    criteria: state.cohort.cohortCriteria,
    aiAssistedCriteria: {
      inclusion: state.cohort.analyzeCohortCriteria.inclusion.map((item) => ({
        criterion: item.criterion,
        id: item.id,
        isSelected: item.isSelected,
      })),
      exclusion: state.cohort.analyzeCohortCriteria.exclusion.map((item) => ({
        criterion: item.criterion,
        id: item.id,
        isSelected: item.isSelected,
      })),
    },
  },
});

const mapDispatchToProps = (dispatch: Dispatch): DispatchProps => ({
  handleClose: () => {
    dispatch(closeModal(MODAL_TYPE.SAVE_COHORT));
  },
});

export default connect(mapStateToProps, mapDispatchToProps)(SaveCohort);
